import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthService {

  public user: any;
  public token: string;
  private serverUrl = environment.serverUrl;

  constructor(private http: HttpClient, public router: Router) {
  }

  loginUser(email: string, password: string) {
    const url = `${this.serverUrl}` + '/login';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(url, { 'email': email, 'password': password }, httpOptions)
      .pipe(
        tap((res: any) => {
          this.token = res.token;
          localStorage.setItem('user_name', res.user_name);
          localStorage.setItem('user_id', res.user_id);
          if (this.token.length) {
            localStorage.setItem('thunder_token', this.token);
          } else {
            localStorage.setItem('thunder_token', '');
          }
          
        })
      );
  }

  autologinUser(email: string) {
    const url = `${this.serverUrl}` + '/autologin';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(url, { 'email': email }, httpOptions)
      .pipe(
        tap((res: any) => {
          this.token = res.token;
          localStorage.setItem('user_name', res.user_name);
          localStorage.setItem('user_id', res.user_id);
          if (this.token.length) {
            localStorage.setItem('thunder_token', this.token);
          } else {
            localStorage.setItem('thunder_token', '');
          }
          
        })
      );
  }
  
  forgotPwd(email: string) {
    const url = `${this.serverUrl}` + '/forgot-pwd';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(url, { 'email': email }, httpOptions);
  }

  resetPwd(email: string, token: string, password: string) {
    const url = `${this.serverUrl}` + '/reset-pwd';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(url, { email: email, token: token, password: password }, httpOptions);
  }

  logout() {
    localStorage.removeItem('thunder_token');
    localStorage.removeItem('user_name');
    localStorage.removeItem('user_id');
    this.router.navigate(['/login']);
  }

  isAuthenticated() {
    this.token = localStorage.getItem('thunder_token');
    if (this.token) return true; else return false;
  }
}
