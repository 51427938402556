import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}
localStorage.setItem("environments", JSON.stringify({
  siteUrl: environment.siteUrl,
  serverUrl: environment.serverUrl,
  socketUrl: environment.socketUrl,
  basePath: environment.basePath,
  videoPath: environment.videoPath,
}));

platformBrowserDynamic().bootstrapModule(AppModule);
