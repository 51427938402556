import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { DatePipe } from '@angular/common';

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AgmCoreModule } from "@agm/core";
import { DeviceDetectorModule } from "ngx-device-detector";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { NgxSpinnerModule } from "ngx-spinner";
import { ToastrModule } from "ngx-toastr";
import { DragulaService } from "ng2-dragula";
import { ImageViewerModule } from "ngx-image-viewer";
import { QuillModule } from 'ngx-quill'
import { UiSwitchModule } from 'ngx-ui-switch';
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
} from "ngx-perfect-scrollbar";

import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { ApiService } from "./shared/api/api.service";
import { PermissionService } from "./shared/services/permission.service";
import { PaymentService } from "./shared/services/payment.service";
import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { NgImageSliderModule } from "ng-image-slider";
import { AuthService } from "./shared/auth/auth.service";
import { AuthGuard } from "./shared/auth/auth-guard.service";
import { WINDOW_PROVIDERS } from "./shared/services/window.service";
import { TimepickerModule } from "ngx-bootstrap/timepicker";
import { AngularMultiSelectModule } from "angular2-multiselect-dropdown";
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxUiLoaderModule } from "ngx-ui-loader";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { ListParentsComponent } from './manage-parents/list-parents/list-parents.component';
import { CreateParentsComponent } from './manage-parents/create-parents/create-parents.component';
// import { NotificationComponent } from './notification/notification.component';





pdfMake.vfs = pdfFonts.pdfMake.vfs;



const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false,
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

@NgModule({
  declarations: [AppComponent, FullLayoutComponent, ContentLayoutComponent, ListParentsComponent, CreateParentsComponent ],
  imports: [
    BrowserAnimationsModule,
    NgxUiLoaderModule,
    TimepickerModule.forRoot(),
    QuillModule.forRoot(),
    AngularMultiSelectModule,
    AppRoutingModule,
    FormsModule,
    BrowserModule,
    SharedModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModule,
    NgImageSliderModule,
    NgxDatatableModule,
    NgxSpinnerModule,
    DeviceDetectorModule.forRoot(),
    ToastrModule.forRoot(),
    ImageViewerModule.forRoot(),
    UiSwitchModule.forRoot({

    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBXoHvfVB__WPK2aVjwipO3QtYIj9xlBjg",
    }),
    PerfectScrollbarModule,
  ],
  providers: [
    AuthService,
    DatePipe,
    AuthGuard,
    ApiService,
    PaymentService,
    DragulaService,
    PermissionService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    WINDOW_PROVIDERS,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
