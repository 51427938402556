import {
	Component,
	Output,
	EventEmitter,
	OnDestroy,
	OnInit,
	AfterViewInit,
	ChangeDetectorRef,
	Inject,
	Renderer2,
	ViewChild,
	ElementRef,
	ViewChildren,
	QueryList,
	HostListener,
} from "@angular/core";
import { SetupService } from "app/setup/setup.service";
import { TranslateService } from "@ngx-translate/core";
import { LayoutService } from "../services/layout.service";
import { Subscription } from "rxjs";
import { ConfigService } from "../services/config.service";
import { NgbModal, ModalDismissReasons, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { DOCUMENT } from "@angular/common";
import { CustomizerService } from "../services/customizer.service";
import { FormControl } from "@angular/forms";
import { ROUTES } from "../vertical-menu/vertical-menu-routes.config";
import { Router } from "@angular/router";
import { AuthService } from "../auth/auth.service";
import { ApiService } from "../api/api.service";
import { InteractionService } from "app/shared/services/interaction.service";
import { User } from "app/shared/interfaces/user.interface";
import { ModalPopupComponent } from "./modal-popup/modal-popup.component";
import { ModalReplyComponent } from "./modal-replymail/modal-reply.component";
@Component({
	selector: "app-navbar",
	templateUrl: "./navbar.component.html",
	styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
	currentLang = "en";
	selectedLanguageText = "English";
	selectedLanguageFlag = "./assets/img/flags/us.png";
	toggleClass = "ft-maximize";
	placement = "bottom-right";
	logoUrl = "assets/img/logo.png";
	schoolName = "Thunder";
	menuPosition = "Side";
	isSmallScreen = false;
	protected innerWidth: any;
	searchOpenClass = "";
	transparentBGClass = "";
	var_sel = "";
	var_sel_qut = "";
	hideSidebar = true;
	public isCollapsed = true;
	layoutSub: Subscription;
	configSub: Subscription;

	@ViewChild("search") searchElement: ElementRef;
	@ViewChildren("searchResults") searchResults: QueryList<any>;

	@Output()
	toggleHideSidebar = new EventEmitter<Object>();

	@Output()
	seachTextEmpty = new EventEmitter<boolean>();

	listItems = [];
	listSchools = [];
	control = new FormControl();

	public config: any = {};

	academicYears = [];
	sessions = [];
	selectedAcadamic = "";
	selectedSession = "";
	selectedSchool = '';
	role: any = "";
	selfWeight: any = -1;
	userData = {
		personal_info: { first_name: "", last_name: "", profile_image: "" },
		user_id: "",
	};
	notificationCount = 0;
	messagesData: any;
	notificationData: any;
	enotificationData = [];
	
	constructor(
		public translate: TranslateService,
		private layoutService: LayoutService,
		private router: Router,
		private configService: ConfigService,
		private interactionService: InteractionService,
		private cdr: ChangeDetectorRef,
		private auth: AuthService,
		private apiService: ApiService,
		private setup: SetupService,
		private modalService: NgbModal
	) {
		const browserLang: string = translate.getBrowserLang();
		translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : "en");
		this.config = this.configService.templateConf;
		this.innerWidth = window.innerWidth;
		this.layoutSub = layoutService.toggleSidebar$.subscribe((isShow) => {
			this.hideSidebar = !isShow;
		});

		// alert(this.notificationData);
		this.apiService.notificationData.subscribe((res) => {
			this.notificationData = res
		});
		this.apiService.notificationCount.subscribe(res => {
			this.notificationCount = res
		})
	}

	ngOnInit() {
		// console.log(this.notificationData);
		// alert(sessionStorage.getItem("ses_year"));
		this.var_sel = this.selectedAcadamic;
		if(sessionStorage.getItem("ses_year") !== null) {
			// alert(sessionStorage.getItem("ses_year"));
			this.var_sel = sessionStorage.getItem("ses_year");
			this.selectedAcadamic = this.var_sel;
			// alert(this.selectedAcadamic);
		}
		
		this.var_sel_qut = this.selectedSession;
		if(sessionStorage.getItem("ses_qut") !== null) {
			// alert(sessionStorage.getItem("ses_qut"));
			this.var_sel = sessionStorage.getItem("ses_qut");
			this.selectedSession = this.var_sel;
			// alert(this.selectedAcadamic);
		}

		setInterval(() => {
			this.cdr.detectChanges();
		}, 100);
		this.apiService.ApiGET("user/loadPermission").subscribe((res: any) => {
			if (res.status) {
				this.interactionService.setPermissions({
					...res.result,
				});
				this.selfWeight = res.result.weight;
			}
		});

		this.loadEmailLogs();

		this.configService.templateConf$.subscribe((templateConf) => {
			if (templateConf) {
				this.config = templateConf;
				this.logoUrl = this.config.layout.sidebar.logo_image;
				if (this.config.layout.sidebar?.school_name) {
					this.schoolName = this.config.layout.sidebar?.school_name;
				} else {
					this.schoolName = "Thunder";
				}
			}
			this.cdr.markForCheck();
		});

		ROUTES.forEach((item) => {
			this.listItems.push({
				url: item.path,
				name: item.title,
				icon: item.icon,
			});
			if (Array.isArray(item.submenu)) {
				item.submenu.forEach((subitem) => {
					this.listItems.push({
						url: subitem.path,
						name: subitem.title,
						icon: subitem.icon,
					});
					if (Array.isArray(subitem.submenu)) {
						subitem.submenu.forEach((subsubitem) => {
							this.listItems.push({
								url: subsubitem.path,
								name: subsubitem.title,
								icon: subsubitem.icon,
							});
						});
					}
				});
			}
		});

		if (this.innerWidth < 1200) {
			this.isSmallScreen = true;
		} else {
			this.isSmallScreen = false;
		}
		setInterval(() => {
			this.cdr.detectChanges();
		}, 100);

		this.loadUserData();
		this.loadAcademicYear();
		this.loadSchools();
		this.setup.triggerSubject.subscribe(() => {
			this.open();
		});

		this.messagesData = [
			{
				message: "Lorem ipsum dolor sit amet, consectetur adipiscing",
				author: "Mike Litorus",
				time: "4 mins ago",
			},
			{
				message: "Lorem ipsum dolor sit amet, consectetur adipiscing",
				author: "Mike Litorus",
				time: "1 hour ago",
			},
			{
				message: "Lorem ipsum dolor sit amet, consectetur adipiscing",
				author: "Mike Litorus",
				time: "4 mins ago",
			},
			{
				message: "Lorem ipsum dolor sit amet, consectetur adipiscing",
				author: "Mike Litorus",
				time: "1 hour ago",
			},
			{
				message: "Lorem ipsum dolor sit amet, consectetur adipiscing",
				author: "Mike Litorus",
				time: "4 mins ago",
			},
			{
				message: "Lorem ipsum dolor sit amet, consectetur adipiscing",
				author: "Mike Litorus",
				time: "1 hour ago",
			},
		];
	}

	loadEmailLogs() {
		this.apiService.ApiGET("user/loadEmailLogs").subscribe((res: any) => {
			if (res.status) {

				 res['result'].forEach( record => {
					record.notification_name = record['subject']
					record.description = record['template']
				});

				this.enotificationData = res['result'];
			}
		});
	}

	loadSchools() {
		this.apiService
		  .ApiGET("user/getSchoolsUnderDistrict")
		  .subscribe((res: any) => {
			this.listSchools = res.result;
		  });
	}

	open() {
		const modalRef = this.modalService.open(ModalPopupComponent);
		modalRef.componentInstance.name = "World";
	}
	edit() {
		this.router.navigate(["/manage-admins/edit-admin/" + this.userData.user_id]);
	}

	goTomailBox() {
		this.router.navigate(["/manage-mail"]);
	}

	loadUserData() {
		this.apiService.ApiGET("").subscribe((res: any) => {
			if (res.status) {
				const result = res.result;
				const user: User = {
					...result,
					role_name: res.role,
					role_weight: res.role_weight,
				};

				this.interactionService.setUserInfo(user);
			}

			this.userData = res.result;

			this.role = res.role;
			this.setup.userData = {...res.result,role_name: res.role, role_weight: res.role_weight}
		});
	}

	loadAcademicYear() {
		this.apiService.ApiGET("setup/getAcademicYear").subscribe((res) => {
			this.academicYears = res.result;
			if (this.academicYears && this.academicYears.length > 0) {
				for (let i = 0; i < this.academicYears.length; i++) {
					const academicYear = this.academicYears[i];
					const startDate = new Date(academicYear.start_date);
					startDate.setHours(0, 0, 0, 0);
					const endDate = new Date(academicYear.end_date);
					endDate.setHours(0, 0, 0, 0);
					const today = new Date();
					today.setHours(0, 0, 0, 0);
					if (today.getTime() >= startDate.getTime() && today.getTime() <= endDate.getTime()) {
						if(sessionStorage.getItem("ses_year") === null) {
							this.selectedAcadamic = academicYear._id;
						}
						// alert("hello");
					}
				}
				if (this.selectedAcadamic == "") this.selectedAcadamic = this.academicYears[0]._id;
				this.setup.academic_id = this.selectedAcadamic;

				this.getSessionData();
				this.interactionService.setAcademicYears(this.academicYears);
			}
		});
	}

	changeAcademic() {
		// alert(sessionStorage.getItem("ses_year"));
		sessionStorage.setItem("ses_year", this.selectedAcadamic);
		// console.log(this.sessions);
		this.getSessionData();
	}
	setSession(event) {
		// alert(this.selectedSession);
		// console.log(event.target.value);
		sessionStorage.setItem("ses_qut", this.selectedSession);
		this.setup.getObservableSession(event.target.value);
		// this.setup.session_id = event.target.value;
		this.apiService.ApiGET("classes/get-classes/" + this.setup.session_id).subscribe((classesData: any) => {
			if (classesData.status && classesData.result) {
				this.interactionService.setClasses(classesData.result);
			}
		});
	}
	getSessionData() {
		this.apiService.ApiGET("setup/getSession/" + this.selectedAcadamic).subscribe((res: any) => {
			this.sessions = [];
			for (let i = 0; i < res.result.length; i++) {
				if (res.result[i].active) {
					this.sessions.push(res.result[i]);
				}
			}
			
			if (this.sessions && this.sessions.length > 0) {
				for (let i = 0; i < this.sessions.length; i++) {
					const session = this.sessions[i];
					const startDate = new Date(session.start_date);
					startDate.setHours(0, 0, 0, 0);
					const endDate = new Date(session.end_date);
					endDate.setHours(0, 0, 0, 0);
					const today = new Date();
					today.setHours(0, 0, 0, 0);
					if (today.getTime() >= startDate.getTime() && today.getTime() <= endDate.getTime()) {
						if(sessionStorage.getItem("ses_qut") === null) {
							this.selectedSession = session._id;
						}
					}
				}
				if (this.selectedSession == "") this.selectedSession = this.sessions[0]._id;
				this.setup.session_id = this.selectedSession;
				this.interactionService.setSessions(this.sessions);
				this.apiService.ApiGET("classes/get-classes/" + this.selectedSession).subscribe((classesData: any) => {
					if (classesData.status && classesData.result) {
						this.interactionService.setClasses(classesData.result);
					}
				});
			}
			
			if(sessionStorage.getItem("ses_qut") == null) {
				if (this.sessions.length > 1) {
					let sessionDefault = this.sessions.filter(res=>res.is_default);
					let session_id = this.sessions[1]._id
					if(sessionDefault.length == 1){
						session_id = sessionDefault[0]._id;
					}
					const event = {
						target: {
							value: session_id,
						},
					};
					this.setSession(event);
				} else {
					const event = {
						target: {
							value: this.sessions[0]._id,
						},
					};
					this.setSession(event);
				}
			} else {
				const event = {
					target: {
						value: sessionStorage.getItem("ses_qut"),
					},
				};
				this.setSession(event);
			}
		});
	}

	ngAfterViewInit() {
		this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
			if (templateConf) {
				this.config = templateConf;
			}
			this.loadLayout();
			this.cdr.markForCheck();
		});
	}

	ngOnDestroy() {
		if (this.layoutSub) {
			this.layoutSub.unsubscribe();
		}
		if (this.configSub) {
			this.configSub.unsubscribe();
		}
	}

	@HostListener("window:resize", ["$event"])
	onResize(event) {
		this.innerWidth = event.target.innerWidth;
		if (this.innerWidth < 1200) {
			this.isSmallScreen = true;
		} else {
			this.isSmallScreen = false;
		}
	}

	loadLayout() {
		if (this.config.layout.menuPosition && this.config.layout.menuPosition.toString().trim() !== "") {
			this.menuPosition = this.config.layout.menuPosition;
		}

		if (this.config.layout.variant === "Light") {
			this.logoUrl = "assets/img/logo-dark.png";
		} else {
			this.logoUrl = "assets/img/logo.png";
		}

		if (this.config.layout.variant === "Transparent") {
			this.transparentBGClass = this.config.layout.sidebar.backgroundColor;
		} else {
			this.transparentBGClass = "";
		}
	}

	onSearchKey(event: any) {
		if (this.searchResults && this.searchResults.length > 0) {
			this.searchResults.first.host.nativeElement.classList.add("first-active-item");
		}

		if (event.target.value === "") {
			this.seachTextEmpty.emit(true);
		} else {
			this.seachTextEmpty.emit(false);
		}
	}

	removeActiveClass() {
		if (this.searchResults && this.searchResults.length > 0) {
			this.searchResults.first.host.nativeElement.classList.remove("first-active-item");
		}
	}

	onEscEvent() {
		this.control.setValue("");
		this.searchOpenClass = "";
		this.seachTextEmpty.emit(true);
	}

	onEnter() {
		if (this.searchResults && this.searchResults.length > 0) {
			const url = this.searchResults.first.url;
			if (url && url !== "") {
				this.control.setValue("");
				this.searchOpenClass = "";
				this.router.navigate([url]);
				this.seachTextEmpty.emit(true);
			}
		}
	}

	redirectTo(value) {
		this.router.navigate([value]);
		this.seachTextEmpty.emit(true);
	}

	ChangeLanguage(language: string) {
		this.translate.use(language);

		if (language === "en") {
			this.selectedLanguageText = "English";
			this.selectedLanguageFlag = "./assets/img/flags/us.png";
		} else if (language === "es") {
			this.selectedLanguageText = "Spanish";
			this.selectedLanguageFlag = "./assets/img/flags/es.png";
		} else if (language === "pt") {
			this.selectedLanguageText = "Portuguese";
			this.selectedLanguageFlag = "./assets/img/flags/pt.png";
		} else if (language === "de") {
			this.selectedLanguageText = "German";
			this.selectedLanguageFlag = "./assets/img/flags/de.png";
		}
	}

	ToggleClass() {
		if (this.toggleClass === "ft-maximize") {
			this.toggleClass = "ft-minimize";
		} else {
			this.toggleClass = "ft-maximize";
		}
	}

	toggleSearchOpenClass(display) {
		this.control.setValue("");
		if (display) {
			this.searchOpenClass = "open";
			setTimeout(() => {
				this.searchElement.nativeElement.focus();
			}, 0);
		} else {
			this.searchOpenClass = "";
		}
		this.seachTextEmpty.emit(true);
	}

	toggleNotificationSidebar() {
		this.layoutService.toggleNotificationSidebar(true);
	}

	toggleSidebar() {
		this.layoutService.toggleSidebarSmallScreen(this.hideSidebar);
	}

	///////////////////////////////////////////////////////////////////////////////////////////////
	updateNotification(res) {
		let data = {
			user_id: localStorage.getItem("user_id"),
			Id: res._id,
			...res
		};
		this.apiService.updateNotifications(data);
	}

	deleteEmailNotification(res) {
		let data = {
			...res
		};
		this.apiService.deleteNotifications(data);
		setTimeout(()=>{
			this.loadEmailLogs();
		},100)
		
	}

	replyNotification(res, data=null){
		const modalRef = this.modalService.open(ModalReplyComponent);
		modalRef.componentInstance.type = res;
		if(data!=null){
			modalRef.componentInstance.to = data['from'];
		} else {
			modalRef.componentInstance.to = "";
		}
	}

	logout() {
		this.auth.logout();
	}
}
