import { Routes, RouterModule } from "@angular/router";
import { VideochatComponent } from "../videochat/videochat.component";
// import { ParentListComponent } from "app/manage-students/parent-list/parent-list.component";
import { FaceAttendanceComponent } from "app/manage-attendance/face-attendance/face-attendance.component";
import { ListParentsComponent } from "app/manage-parents/list-parents/list-parents.component";
import { CreateParentsComponent } from "app/manage-parents/create-parents/create-parents.component";
// Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: "dashboard",
    loadChildren: () =>
      import("../../dashboard/dashboard.module").then((m) => m.DashboardModule),
  },
  {
    path:'apps',
    loadChildren:()=> import("../../apps/chating/chat-module.module").then((m)=>m.ChatModuleModule)
  },
  {
    path: "my-to-dos",
    loadChildren: () =>
      import("../../mytodos/mytodos.module").then((m) => m.MytodosModule),
  },
  {
    path: "issue",
    loadChildren: () =>
      import("../../issue/issue.module").then((m) => m.IssueModule),
  },
  {
    path: "timesheet",
    loadChildren: () =>
      import("../../timesheet/timesheet.module").then((m) => m.TimesheetModule),
  },
 
  {
    path: "manage-attendance",
    loadChildren: () =>
      import("../../manage-attendance/manage-attendance.module").then(
        (m) => m.ManageAttendanceModule
      ),
  },
  {
    path: "manual-attendance",
    loadChildren: () =>
      import("../../manual-attendance/manual-attendance.module").then(
        (m) => m.ManualAttendanceModule
      ),
  },
  {
    path: "appointment",
    loadChildren: () =>
      import("../../appointment/appointment.module").then(
        (m) => m.AppointmentModule
      ),
  },
  {
    path: "schedule-information",
    loadChildren: () =>
      import("../../schedule-information/schedule-information.module").then(
        (m) => m.ScheduleInformationModule
      ),
  },
  {
    path: "manage-academics",
    loadChildren: () =>
      import("../../manage-academics/manage-academics.module").then(
        (m) => m.ManageAcademicsModule
      ),
  },
  {
    path: "manage-exam",
    loadChildren: () =>
      import("../../manage-exam/manage-exam.module").then(
        (m) => m.ManageExamModule
      ),
  },
  {
    path: "manage-library",
    loadChildren: () =>
      import("../../manage-library/manage-library.module").then(
        (m) => m.ManageLibraryModule
      ),
  },
  {
    path: "manage-material",
    loadChildren: () =>
      import("../../manage-materials/manage-materials.module").then(
        (m) => m.ManageMaterialsModule
      ),
  },
  {
    path: "manage-bookstore",
    loadChildren: () =>
      import("../../manage-bookstore/manage-bookstore.module").then(
        (m) => m.ManageBookstoreModule
      ),
  },
  {
    path: "manage-students",
    loadChildren: () =>
      import("../../manage-students/manage-students.module").then(
        (m) => m.ManageStudentsModule
      ),
  },
  {
    path: "manage-events",
    loadChildren: () =>
      import("../../manage-events/manage-events.module").then(
        (m) => m.ManageEventsModule
      ),
  },
  {
    path: "setup",
    loadChildren: () =>
      import("../../setup/setup.module").then((m) => m.SetupModule),
  },
  {
    path: "hr",
    loadChildren: () =>
      import("../../manage-hr/manage-hr.module").then((m) => m.ManageHrModule),
  },
  {
    path: "manage-message",
    loadChildren: () =>
      import("../../manage-message/manage-message.module").then(
        (m) => m.ManageMessageModule
      ),
  },
  {
    path: "manage-fees",
    loadChildren: () =>
      import("../../manage-fees/manage-fees.module").then(
        (m) => m.ManageFeesModule
      ),
  },
  {
    path: "manage-account",
    loadChildren: () =>
      import("../../manage-account/manage-account.module").then(
        (m) => m.ManageAccountModule
      ),
  },
  {
    path: "manage-admins",
    loadChildren: () =>
      import("../../manage-admins/manage-admins.module").then(
        (m) => m.ManageAdminsModule
      ),
  },
  {
    path: "school-admins",
    loadChildren: () =>
      import("../../school-admins/school-admins.module").then(
        (m) => m.SchoolAdminsModule
      ),
  },
  {
    path: "activity-logs",
    loadChildren: () =>
      import("../../activity-logs/activity-logs.module").then(
        (m) => m.ActivityLogsModule
      ),
  },
  {
    path: "payment",
    loadChildren: () =>
      import("../eCommerce/eCommerce.component.module").then(
        (m) => m.eCommerceModule
      ),
  },
  {
    path: "chat/:mode/:room",
    component: VideochatComponent,
  },
  {
    path: "schools",
    loadChildren: () =>
      import("../../schools/schools.module").then((m) => m.SchoolsModule),
  },
  {
    path: "districts",
    loadChildren: () =>
      import("../../districts/districts.module").then((m) => m.DistrictsModule),
  },
  {
    path: "parents",
    loadChildren: () =>
      import("../../manage-students/parent-list/parent-list.module").then((m) => m.ParentListModule)
  },
  {
    path: "",
    loadChildren: () =>
      import("../../manage-attendance/manage-attendance.module").then(
        (m) => m.ManageAttendanceModule
      ),
  },
  {
    path: "manage-parent",
    component: ListParentsComponent,
  },
  {
    path: "manage-parent/:id/:fullname/add-parent",
    component: CreateParentsComponent,
  },
  {
    path: "manage-parent/:id/:fullname/edit-parent/:parentid",
    component: CreateParentsComponent,
  },
  {
    path: "notification",
    loadChildren: () =>
      import("../../notification/notifications.module").then((m) => m.NotificationsModule),
  },
  {
    path: 'inbox',
    loadChildren: () => import('../../inbox/inbox.module').then(m => m.InboxModule)
  },
];
