import { environment } from "../../../environments/environment";

export const ROUTES: any[] = [
	{
		path: "/dashboard",
		module: "",
		title: "Dashboard",
		icon: "ft-home",
		class: "",
		badge: "",
		badgeClass: "",
		showDefault: true,
		isExternalLink: false,
		ignoreRoles: [],
		specRoles: [],
		submenu: [],
	},
	{
		path: "",
		module: "",
		title: "Districts & Schools",
		icon: "icon-badge",
		class: "has-sub",
		badge: "",
		badgeClass: "",
		isExternalLink: false,
		ignoreRoles: [2, 3, 4, 5, 6, 7, 8, 9],
		specRoles: [0, 1],
		submenu: [
			{
				path: "/districts",
				module: "districts",
				title: "Districts",
				icon: "icon-support",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [1],
				specRoles: [0],
				submenu: [],
			},
			{
				path: "/schools",
				module: "schools",
				title: "Schools",
				icon: "icon-graduation",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [0],
				submenu: [],
			},
		],
	},
	{
		path: "/my-to-dos",
		module: "todo",
		title: "My To-Dos",
		icon: "fa fa-list-alt",
		class: "",
		badge: "",
		badgeClass: "",
		isExternalLink: false,
		ignoreRoles: [],
		specRoles: [],
		submenu: [],
	},
	{
		path: "/issue",
		module: "issues",
		title: "Issue",
		icon: "ft-alert-octagon",
		class: "",
		badge: "",
		badgeClass: "",
		isExternalLink: false,
		ignoreRoles: [],
		specRoles: [],
		submenu: [],
	},
	{
		path: "/appointment/manage-appointment",
		module: "appointments",
		title: "Appointment",
		icon: "ft-radio",
		class: "",
		badge: "",
		badgeClass: "",
		isExternalLink: false,
		ignoreRoles: [],
		specRoles: [],
		submenu: [],
	},
	{
		path: "/schedule-information",
		module: "schedule",
		title: "Schedule",
		icon: "ft-calendar",
		class: "",
		badge: "",
		badgeClass: "",
		isExternalLink: false,
		ignoreRoles: [],
		specRoles: [],
		submenu: [],
	},
	{
		path: "/timesheet",


		module: "timesheet",
		title: "Timesheet",
		icon: "fa fa-clock-o",
		class: "",
		badge: "",
		badgeClass: "",
		isExternalLink: false,
		ignoreRoles: [0, 5, 6],
		specRoles: [],
		isCommon: true,
		submenu: [],
	},
	{
		path: environment.videoPath + "/landing.html",
		module: "Start Video Conf",
		title: "Start Video Conf",
		icon: "fa fa-users",
		class: "",
		badge: "",
		badgeClass: "",
		isExternalLink: true,
		ignoreRoles: [],
		specRoles: [],
		isCommon: true,
		submenu: [],
	},
	{
		path: "/manage-academics/virtual-class",
		module: "Virtual Class",
		title: "Virtual Class",
		icon: "fa fa-users",
		class: "",
		badge: "",
		badgeClass: "",
		isExternalLink: false,
		ignoreRoles: [],
		specRoles: [],
		isCommon: true,
		submenu: [],
	},
	{
		path: "/face-attendance",
		module: "attendance",
		title: "Face Attendance",
		icon: "fa fa-users",
		class: "",
		badge: "",
		badgeClass: "",
		isExternalLink: false,
		ignoreRoles: [0, 5, 6],
		specRoles: [],
		submenu: [],
	},
	{
		path: "",
		module: "",
		title: "Academics",
		icon: "fa fa-th",
		class: "has-sub",
		badge: "",
		badgeClass: "",
		isClient: false,
		isExternalLink: false,
		ignoreRoles: [0, 5, 6],
		specRoles: [],
		submenu: [
			{
				path: "/manage-academics/lectures",
				module: "lectures",
				title: "Lectures",
				icon: "fa fa-sitemap",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [],
				submenu: [],
			},
			{
				path: "/manage-academics/assignments",
				module: "assignments",
				title: "Assignments",
				icon: "fa fa-file",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [],
				submenu: [],
			},
			{
				path: "/manage-academics/assign-grade-assignment",
				module: "Grade Assignment",
				title: "Grade Assignment",
				icon: "fa fa-edit",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [],
				submenu: [],
			},
			{
				path: "/manage-academics/homework",
				module: "homework",
				title: "Homework",
				icon: "fa icon-home",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [],
				submenu: [],
			},
			{
				path: "/manage-academics/assign-grade",
				module: "Grade Homework",
				title: "Grade Homework",
				icon: "fa fa-edit",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [],
				submenu: [],
			},
			// {
			//   path: '/setup/academic-maps',
			//   module: 'academicresults',
			//   title: 'Academic Map',
			//   icon: 'ft-target',
			//   class: '',
			//   badge: '',
			//   badgeClass: '',
			//   isExternalLink: false,
			//   ignoreRoles: [0],
			//   specRoles: [],
			//   submenu: []
			// },
			
			{
				path: "/manage-academics/class-curriculum",
				module: "ClassCurriculum",
				title: "Class Curriculum",
				icon: "fa fa-th",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [],
				submenu: [],
			},
			{
				path: "/manage-academics/curriculum-template",
				module: "CurriculumTemplate",
				title: "Curriculum Template",
				icon: "fa fa-file-o",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [],
				submenu: [],
			},
			{
				path: "/manage-academics/user-replacement",
				module: "SubstitutionTeacher",
				title: "Substitute Teacher",
				icon: "fa fa-users",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [0],
				specRoles: [],
				submenu: [],
			},
			{
				path: "",
				module: "exams",
				title: "Exams",
				icon: "fa fa-question-circle",
				class: "has-sub",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [0],
				specRoles: [],
				submenu: [
					{
						path: "/manage-exam/exams",
						module: "exams",
						title: "Exam",
						icon: "fa fa-check",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/manage-exam/result",
						module: "exams",
						title: "Result",
						icon: "ft-check-square",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
				],
			}
			// {
			//   path: '/manage-academics/student-academic-result',
			//   module: 'grades',
			//   title: 'Student Grades',
			//   icon: 'fa icon-home',
			//   class: '',
			//   badge: '',
			//   badgeClass: '',
			//   isExternalLink: false,
			//   ignoreRoles: [],
			//   specRoles: [],
			//   submenu: []
			// },
		],
	},
	{
		path: "/manage-library",
		module: "library",
		title: "Library",
		icon: "fa fa-book",
		class: "",
		badge: "",
		badgeClass: "",
		isExternalLink: false,
		ignoreRoles: [0],
		specRoles: [],
		submenu: [],
	},
	{
		path: "/manage-material",
		module: "materials",
		title: "Materials",
		icon: "fa fa-file-text",
		class: "",
		badge: "",
		badgeClass: "",
		isExternalLink: false,
		ignoreRoles: [0],
		specRoles: [],
		submenu: [],
	},
	{
		path: "",
		module: "bookstores",
		title: "Bookstore",
		icon: "fa fa-book",
		class: "has-sub",
		isClient: false,
		badge: "",
		badgeClass: "",
		isExternalLink: false,
		ignoreRoles: [0],
		specRoles: [],
		submenu: [
			{
				path: "/manage-bookstore/category",
				module: "BookCategories",
				title: "Book Category",
				icon: "icon-book-open",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [0],
				specRoles: [],
				submenu: [],
			},
			{
				path: "/manage-bookstore/book",
				module: "BookStores",
				title: "Book Store",
				icon: "ft-check-square",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [0],
				specRoles: [],
				submenu: [],
			},
		],
	},
	{
		path: "",
		module: "students",
		title: "Students",
		icon: "fa fa-users",
		class: "has-sub",
		badge: "",
		badgeClass: "",
		isExternalLink: false,
		ignoreRoles: [0],
		specRoles: [],
		submenu: [
			{
				path: "/manage-students/attendances",
				module: "attendance",
				title: "Attendance",
				icon: "fa fa-th-list",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [0],
				specRoles: [],
				submenu: [],
			},
			{
				path: "/manual-attendance",
				module: "manage-attendance",
				title: "See Attendance",
				icon: "fa fa-clock-o",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [0],
				specRoles: [],
				submenu: [],
			},
			{
				path: "/manage-students/admissions-inquiry",
				module: "admissioninquiry",
				title: "Admission/Inquiry",
				icon: "fa fa-user",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [],
				submenu: [],
			},
			{
				path: "/manage-students/promote-student",
				module: "Promote student",
				title: "Promote Student",
				icon: "ft-bar-chart",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [],
				submenu: [],
			},
			{
				path: "/manage-students/students",
				module: "studentsList",
				title: "Students List",
				icon: "fa fa-users",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [],
				submenu: [],
			},
			{
				path: "/manage-students/student-relations",
				module: "studentrelations",
				title: "Student Relations",
				icon: "fa icon-users",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [0],
				specRoles: [],
				submenu: [],
			},
			// {
			//   path: '/manage-students/student-relations',
			//   module: '',
			//   title: 'Student Relations',
			//   icon: 'fa icon-users',
			//   class: '',
			//   badge: '',
			//   badgeClass: '',
			//   isExternalLink: false,
			//   ignoreRoles: [],
			//   specRoles: [],
			//   submenu: []
			// },
			{
				path: "/manage-students/assigned-students-list",
				module: "Assign Students",
				title: "Assign Students",
				icon: "fa fa-id-card",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [],
				submenu: [],
			},
			{
				path: "/manage-students/parents-list",
				module: "Parents List",
				title: "Parents List",
				icon: "fa fa-users",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [],
				submenu: [],
			},
			{
				path: "/manage-parent",
				module: "Parents",
				title: "Parents",
				icon: "fa fa-users",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [0, 1, 2, 3, 4, 5, 7],
				specRoles: [6],
				submenu: [],
			},
			{
				path: "/manage-academics/student-academic-result",
				module: "Academic Result",
				title: "Academic Result",
				icon: "ft-sunrise",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [],
				submenu: [],
			},
			{
				path: "",
				module: "sources",
				title: "Sources",
				icon: "fa fa-clipboard",
				class: "has-sub",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [],
				submenu: [
					{
						path: "/manage-students/manage-sources",
						module: "sources",
						title: "Sources",
						icon: "ft-tv",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/manage-students/add-sources",
						module: "sources",
						title: "Add Sources",
						icon: "ft-tv",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
				],
			},
		],
	},
	{
		path: "",
		module: "events",
		title: "Events",
		icon: "fa fa-calendar-o",
		class: "has-sub",
		badge: "",
		badgeClass: "",
		isExternalLink: false,
		ignoreRoles: [0],
		specRoles: [],
		submenu: [
			{
				path: "/manage-events/event",
				module: "events",
				title: "Events",
				icon: "fa fa-calendar-o",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [0],
				specRoles: [],
				submenu: [],
			},
			{
				path: "/manage-events/event-registration",
				module: "events",
				title: "Event Registrations",
				icon: "fa fa-registered",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [0],
				specRoles: [],
				submenu: [],
			},
			{
				path: "/manage-events/event-gallery",
				module: "events",
				title: "Event Gallery",
				icon: "fa fa-image",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [0],
				specRoles: [],
				submenu: [],
			},
		],
	},
	{
		path: "",
		module: "",
		title: "eCommerce",
		icon: "fa fa-shopping-cart",
		class: "has-sub",
		badge: "",
		badgeClass: "",
		isExternalLink: false,
		ignoreRoles: [],
		specRoles: [],
		submenu: [
			{
				path: environment.urlEcommAdmin + "/autologin/" + localStorage.getItem("thunder_token"),
				module: "eCommerce Admin",
				title: "Admin",
				icon: "fa fa-user",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: true,
				ignoreRoles: [],
				specRoles: [],
				submenu: [],
			},
			{
				path: environment.urlEcommWebsite + "website-login/" + localStorage.getItem("thunder_token"),
				module: "eCommerce",
				title: "Website",
				icon: "fa fa-globe",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: true,
				ignoreRoles: [],
				specRoles: [],
				submenu: [],
			},
		],
	},
	{
		path: "/inbox",
		module: "",
		title: "Mail",
		icon: "ft-mail",
		class: "",
		badge: "",
		badgeClass: "",
		showDefault: true,
		isExternalLink: false,
		ignoreRoles: [],
		specRoles: [],
		submenu: [],
	},
	{
		path: "",
		module: "HR",
		title: "HR",
		icon: "fa fa-th-list",
		class: "has-sub",
		badge: "",
		badgeClass: "",
		isExternalLink: false,
		ignoreRoles: [0],
		specRoles: [1, 3],
		submenu: [
			{
				path: "/manage-attendance",
				module: "HR",
				title: "Attendance (Admin)",
				icon: "fa fa-clock-o",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [0],
				specRoles: [],
				submenu: [],
			},
			{
				path: "",
				module: "A",
				title: "Account",
				icon: "fa fa-bank",
				class: "has-sub",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [],
				submenu: [
					{
						path: "/hr/estimate",
						module: "estimates",
						title: "Estimates",
						icon: "fa icon-docs",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/hr/invoice",
						module: "invoices",
						title: "Invoices",
						icon: "fa fa-check",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/hr/payment",
						module: "payments",
						title: "Payments",
						icon: "fa fa-check-circle",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/hr/expense",
						module: "expenses",
						title: "Expenses",
						icon: "fa fa-buysellads",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/hr/provident",
						module: "provident",
						title: "Provident Fund",
						icon: "fa fa-product-hunt",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/hr/tax",
						module: "taxes",
						title: "Taxes",
						icon: "fa fa-dollar",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
				],
			},

			
			{
				path: "",
				module: "fees",
				title: "Fees",
				icon: "fa fa-bank",
				class: "has-sub",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [],
				submenu: [
					{
						path: "/manage-fees/manage-bank",
						module: "fees",
						title: "Bank",
						icon: "fa fa-th-list",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/manage-fees/fees-category",
						module: "fees",
						title: "Category",
						icon: "fa fa-th-list",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/manage-fees/fees-heads",
						module: "fees",
						title: "Cost Center",
						icon: "fa fa-th-list",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/manage-fees/fees-heads-group",
						module: "fees",
						title: "Cost Center Group",
						icon: "fa fa-th-list",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/manage-fees/fees-heads-group-mapping",
						module: "fees",
						title: "Cost Center Group Map",
						icon: "fa fa-th-list",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/manage-fees/fees-standard-mapping",
						module: "fees",
						title: "Standard Map",
						icon: "fa fa-th-list",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
				],
			},
			{
				path: "",
				module: "",
				title: "Payroll",
				icon: "fa fa-dollar",
				class: "has-sub",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [],
				submenu: [
					{
						path: "/hr/employee-salary",
						module: "salary",
						title: "Employee Salary",
						icon: "fa icon-docs",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/hr/payslip",
						module: "payslips",
						title: "PaySlip",
						icon: "fa fa-file-text",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/hr/payroll-item",
						module: "PayrollItem",
						title: "Payroll Items",
						icon: "fa fa-th",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
				],
			},
			{
				path: "hr/manage-policy",
				module: "policies",
				title: "Policies",
				icon: "fa fa-recycle",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [],
				submenu: [],
			},
			{
				path: "",
				module: "",
				title: "Reports",
				icon: "fa ft-trending-up",
				class: "has-sub",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [],
				submenu: [
					{
						path: "/hr/report-expense",
						module: "report-expense",
						title: "Expense Report",
						icon: "fa icon-docs",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/hr/report-invoice",
						module: "report-invoice",
						title: "Expense Invoice",
						icon: "fa icon-docs",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
				],
			},
		],
	},
	{
		path: "",
		module: "",
		title: "HR and Payroll",
		icon: "fa fa-shopping-cart",
		class: "has-sub",
		badge: "",
		badgeClass: "",
		isExternalLink: false,
		ignoreRoles: [],
		specRoles: [],
		submenu: [
			{
				path: environment.urlHrModule + "/admin/login/" + localStorage.getItem("thunder_token"),
				module: "hrAdminLeftMenu",
				title: "Admin",
				icon: "fa fa-user",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: true,
				ignoreRoles: [],
				specRoles: [],
				submenu: [],
			},
			{
				path: environment.urlHrModule + "/employee/login/" + localStorage.getItem("thunder_token"),
				module: "hrEmployeeLeftMenu",
				title: "Employee",
				icon: "fa fa-globe",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: true,
				ignoreRoles: [],
				specRoles: [],
				submenu: [],
			},
		],
	},
	{
		path: "/notification",
		module: "notification",
		title: "Notification",
		icon: "ft-bell",
		class: "",
		badge: "",
		badgeClass: "",
		isExternalLink: false,
		ignoreRoles: [],
		specRoles: [],
		submenu: [],
	},
	{
		path: "/activity-logs",
		module: "activitylogs",
		title: "Activity Logs",
		icon: "fa fa-address-book",
		class: "",
		badge: "",
		badgeClass: "",
		isExternalLink: false,
		ignoreRoles: [],
		specRoles: [],
		submenu: [],
	},
	
	{
		path: "",
		module: "Setup",
		title: "Setup",
		icon: "fa fa-cog",
		class: "has-sub",
		badge: "",
		badgeClass: "",
		isExternalLink: false,
		ignoreRoles: [5, 6, 7, 8, 9],
		specRoles: [],
		submenu: [
			{
				path: "/appointment/appointment-about",
				module: "AppointmentAbout",
				title: "Appointment About",
				icon: "fa fa-user-plus",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [],
				submenu: [],
			},
			{
				path: "",
				module: "Email/SMS",
				title: "Email/SMS",
				icon: "fa fa-commenting-o",
				class: "has-sub",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [],
				submenu: [
					{
						path: "/manage-message/email-configuration",
						module: "emailservice",
						title: "Email Config",
						icon: "fa fa-envelope-o",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
					// {
					// 	path: "/manage-message/email-templates",
					// 	module: "emailservice",
					// 	title: "Email Templates",
					// 	icon: "fa fa-newspaper-o",
					// 	class: "",
					// 	badge: "",
					// 	badgeClass: "",
					// 	isExternalLink: false,
					// 	ignoreRoles: [],
					// 	specRoles: [],
					// 	submenu: [],
					// },
					{
						path: "/manage-message/email-logs",
						module: "emaillogs",
						title: "Email Logs",
						icon: "fa fa-envelope-open",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/manage-message/sms-templates",
						module: "smstemplates",
						title: "Templates",
						icon: "ft-message-circle",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/manage-message/sms-logs",
						module: "smslogs",
						title: "SMS Logs",
						icon: "fa fa-envelope-open",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
				],
			},
			{
				path: "",
				module: "",
				title: "Payment Management",
				icon: "fa fa-commenting-o",
				class: "has-sub",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [],
				submenu: [
					{
						path: "/setup/payment-credentials",
						module: "paymentcredentials",
						title: "PAYMENT CREDENTIALS",
						icon: "fa fa-envelope-open",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					}, {
						path: "/setup/make-payments",
						module: "makepayments",
						title: "MAKE PAYMENTS",
						icon: "fa fa-envelope-open",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
				],
			},
			{
				path: "/setup/user-logs",
				module: "Logs",
				title: "Logs",
				icon: "fa fa-user-plus",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [],
				submenu: [],
			},
			{
				path: "/setup/manage-roles",
				module: "roles",
				title: "Roles",
				icon: "fa fa-check-square-o",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [],
				submenu: [],
			},
			{
				path: "/setup/manage-staff",
				module: "staff",
				title: "Staff",
				icon: "fa fa-users",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [2, 3],
				submenu: [],
			},
			{
				path: "/manage-admins",
				module: "users",
				title: "Users",
				icon: "fa fa-users",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [3],
				specRoles: [],
				submenu: [],
			},
			{
				path: "",
				module: "SetupSchools",
				title: "Schools",
				icon: "fa fa-clipboard",
				class: "has-sub",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [0],
				specRoles: [],
				submenu: [
					{
						path: "/setup/school-sessions",
						module: "session",
						title: "School Sessions",
						icon: "fa fa-sitemap",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [0],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/setup/manage-classes",
						module: "classes",
						title: "Classes",
						icon: "fa icon-drawer",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [0],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/setup/school-grades",
						module: "grades",
						title: "School Grades",
						icon: "fa fa-th-list",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [0],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/setup/manage-subjects",
						module: "subjects",
						title: "Subjects",
						icon: "fa icon-docs",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [0],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/setup/departments",
						module: "departments",
						title: "Departments",
						icon: "fa fa-building-o",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [0],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/setup/subject-type",
						module: "subjecttypes",
						title: "Subject Type",
						icon: "fa icon-home",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [0],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/setup/garding-selection",
						module: "grades",
						title: "Pre Grade Selection",
						icon: "fa fa-check-square",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [0],
						specRoles: [],
						submenu: [],
					}
				]
			},
			// {
			// path: '/school-admins',
			// module: 'schooladmins',
			// title: 'School Admins',
			// icon: 'fa fa-users',
			// class: '',
			// badge: '',
			// badgeClass: '',
			// isExternalLink: false,
			// ignoreRoles: [],
			// specRoles: [],
			// submenu: []
			// },

			{
				path: "",
				module: "clone",
				title: "Clone Items",
				icon: "fa fa-clipboard",
				class: "has-sub",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [0],
				specRoles: [],
				submenu: [
					{
						path: "/manage-academics/clone-assignment",
						module: "clone",
						title: "Assignments",
						icon: "fa fa-file",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [0],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/manage-academics/clone-homework",
						module: "clone",
						title: "Homework",
						icon: "fa icon-home",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [0],
						specRoles: [],
						submenu: [],
					},

					{
						path: "/manage-exam/clone-exam",
						module: "clone",
						title: "Exam",
						icon: "fa fa-check",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [0],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/setup/clone-classes",
						module: "clone",
						title: "Classes",
						icon: "fa icon-drawer",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [0],
						specRoles: [],
						submenu: [],
					},
				],
			},

			{
				path: "/setup/manage-locations",
				module: "locations",
				title: "Locations",
				icon: "fa icon-target",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [0],
				specRoles: [],
				submenu: [],
			},

			// {
			//   path: '/setup/school-designations',
			//   module: 'designations',
			//   title: 'School Designations',
			//   icon: 'fa fa-file-text',
			//   class: '',
			//   badge: '',
			//   badgeClass: '',
			//   isExternalLink: false,
			//   ignoreRoles: [0],
			//   specRoles: [],
			//   submenu: []
			// },
			// {
			//   path: '/setup/manage-source',
			//   module: 'source',
			//   title: 'Source',
			//   icon: 'fa fa-list-alt',
			//   class: '',
			//   badge: '',
			//   badgeClass: '',
			//   isExternalLink: false,
			//   ignoreRoles: [],
			//   specRoles: [],
			//   submenu: []
			// },
			{
				path: "/setup/send-email-users",
				module: "mailservice",
				title: "Email Users",
				icon: "ft-target",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [0],
				specRoles: [],
				submenu: [],
			},
			// {
			//   path: '/setup/manage-holidays',
			//   module: 'holidays',
			//   title: 'Manage Holidays',
			//   icon: 'ft-tv',
			//   class: '',
			//   badge: '',
			//   badgeClass: '',
			//   isExternalLink: false,
			//   ignoreRoles: [0],
			//   specRoles: [],
			//   submenu: []
			// },
			{
				path: "",
				module: "",
				title: "Allergies",
				icon: "fa fa-clipboard",
				class: "has-sub",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [],
				submenu: [
					{
						path: "/setup/manage-allergies",
						module: "allergies",
						title: "Allergies",
						icon: "ft-tv",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/setup/add-allergies",
						module: "allergies",
						title: "Add Allergies",
						icon: "ft-tv",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
				],
			},
			{
				path: "",
				module: "",
				title: "Immunizations",
				icon: "fa fa-clipboard",
				class: "has-sub",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [],
				submenu: [
					{
						path: "/setup/manage-immunizations",
						module: "immunizations",
						title: "Immunizations",
						icon: "ft-tv",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/setup/add-immunizations",
						module: "immunizations",
						title: "Add Immunization",
						icon: "ft-tv",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
				],
			},
			{
				path: "",
				module: "skills",
				title: "Skills",
				icon: "fa fa-clipboard",
				class: "has-sub",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [0],
				specRoles: [],
				submenu: [
					{
						path: "/setup/manage-skills",
						module: "skills",
						title: "Skills",
						icon: "ft-tv",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/setup/add-skills",
						module: "skills",
						title: "Add skills",
						icon: "ft-tv",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
				],
			},
			{
				path: "",
				module: "",
				title: "Countries",
				icon: "fa fa-clipboard",
				class: "has-sub",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [0],
				specRoles: [],
				submenu: [
					{
						path: "/setup/manage-countries",
						module: "countries",
						title: "Countries",
						icon: "ft-tv",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
					{
						path: "/setup/add-country",
						module: "countries",
						title: "Add Country",
						icon: "ft-tv",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
				],
			},

			{
				path: "",
				module: "",
				title: "States",
				icon: "fa fa-clipboard",
				class: "has-sub",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [0],
				specRoles: [],
				submenu: [
					{
						path: "/setup/add-state",
						module: "states",
						title: "Add State",
						icon: "ft-tv",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [],
						specRoles: [],
						submenu: [],
					},
				],
			},
			{
				path: "",
				module: "",
				title: "Cities",
				icon: "fa fa-clipboard",
				class: "has-sub",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [0],
				specRoles: [],
				submenu: [
					// {
					//   path: '/setup/manage-cities',
					//   module: 'cities',
					//   title: 'Manage Cities',
					//   icon: 'ft-tv',
					//   class: '',
					//   badge: '',
					//   badgeClass: '',
					//   isExternalLink: false,
					//   ignoreRoles: [],
					//   specRoles: [],
					//   submenu: []
					// },
					{
						path: "/setup/add-city",
						module: "cities",
						title: "Add City",
						icon: "ft-tv",
						class: "",
						badge: "",
						badgeClass: "",
						isExternalLink: false,
						ignoreRoles: [0],
						specRoles: [],
						submenu: [],
					},
				],
			},
			{
				path: "/setup/manage-holidays",
				module: "holidays",
				title: "Holidays",
				icon: "fa fa-check-square-o",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [],
				specRoles: [],
				submenu: [],
			},
			{
				path: "/setup/academic-year",
				module: "Academic years",
				title: "Academic Year",
				icon: "fa fa-list-alt",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [0],
				specRoles: [],
				submenu: [],
			},
			{
				path: "/setup/manage-reports",
				module: "Reports",
				title: "Reports",
				icon: "fa fa-list-alt",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [0],
				specRoles: [],
				submenu: [],
			},
			{
				path: "/setup/manageCredentials",
				module: "Credentials",
				title: "Credentials",
				icon: "fa fa-list-alt",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				ignoreRoles: [0],
				specRoles: [],
				submenu: [],
			},
			{
				path: "/setup/reset-database",
				module: "Reset DB",
				title: "Reset",
				icon: "fa fa-refresh",
				class: "",
				badge: "",
				badgeClass: "",
				isExternalLink: false,
				showDefault: false,
				ignoreRoles: [],
				specRoles: [],
				submenu: [],
			}
		],
	}
];
