/// <reference types="@types/googlemaps" />
import { Component, ViewChild, OnInit, AfterViewInit } from "@angular/core";
import { NgForm, FormGroup, FormControl, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { SetupService } from "app/setup/setup.service";
import { ToastrService } from "ngx-toastr";
import { StudentService } from "../../manage-students/student.service";
import { ApiService } from "app/shared/api/api.service";

@Component({
  selector: "app-create-parents",
  templateUrl: "./create-parents.component.html",
  styleUrls: ["./create-parents.component.scss"],
})
export class CreateParentsComponent implements OnInit, AfterViewInit {
  @ViewChild("address") addresstext: any;

  form = new FormGroup({
    first_name: new FormControl("", [Validators.required]),
    relation_id: new FormControl("", [Validators.required]),
    gender: new FormControl("", [Validators.required]),
    race: new FormControl(""),
    pickup: new FormControl(""),
    email: new FormControl("", [Validators.required]),
    phone: new FormControl("", [Validators.required]),
    address: new FormControl("", [Validators.required]),
    note: new FormControl(""),
  });

  parentlist: any = [];
  realtion: any = [];
  studentId: any = {};
  image: any = "";
  parentFormData: any = {};
  pickup: any = "";
  studentName: any = "";
  parentId = "";
  editMode = false;
  address = "";
  zip_code = "";

  constructor(
    private studentService: StudentService,
    private apiService: ApiService,
    private router: Router,
    private toster: ToastrService,
    private setup: SetupService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.realtionList();
    this.studentId = this.route.snapshot.paramMap.get("id");
    this.studentName = this.route.snapshot.paramMap.get("fullname");
    this.parentId = this.route.snapshot.paramMap.get("parentid");
    if (this.parentId) {
      this.editMode = true;
    }
    this.parentList();
  }
  ngAfterViewInit(): void {
    this.setCurrentPosition();
  }
  private setCurrentPosition() {
    const autocomplete = new google.maps.places.Autocomplete(
      this.addresstext.nativeElement,
      {
        componentRestrictions: { country: "US" },
        types: ["address"], // 'establishment' / 'address' / 'geocode'
      }
    );
    google.maps.event.addListener(autocomplete, "place_changed", () => {
      const place = autocomplete.getPlace();
      this.address = place.formatted_address;
      this.zip_code = place.address_components.find(
        (e, i) =>
          i > 2 && !isNaN(parseInt(e.long_name)) && e.long_name.length === 5
      )?.long_name;
    });
  }

  setDefault() {
    const parent = this.parentlist.find((e) => e.user_id == this.parentId);
    this.address = parent.contact_info?.address1
      ? parent.contact_info?.address1
      : "";
    this.form.setValue({
      first_name: parent.personal_info?.first_name
        ? parent.personal_info?.first_name
        : "",
      relation_id: parent.relation ? parent.relation._id : null,
      gender: parent.personal_info?.gender ? parent.personal_info?.gender : 1,
      race: parent.personal_info?.race ? parent.personal_info?.race : "indian",
      pickup: parent.pickup ? parent.pickup : "0",
      email: parent.email ? parent.email : "",
      phone: parent.contact_info?.phone ? parent.contact_info?.phone : "",
      address: parent.contact_info?.address1
        ? parent.contact_info?.address1
        : "",
      note: parent.personal_info?.note ? parent.personal_info?.note : "",
    });
    this.image = parent.personal_info?.profile_image
      ? parent.personal_info?.profile_image
      : "";
  }

  get lf() {
    return this.form.controls;
  }

  realtionList() {
    this.studentService.getRelations().subscribe((res: any) => {
      this.realtion = res.result;
    });
  }

  async parentList() {
    await this.studentService.parentList().subscribe((res: any) => {
      this.parentlist = res.result;
      this.setDefault();
    });
  }

  addParent() {
    const form = this.form;
    if (
      !form.value.first_name ||
      !form.value.relation_id ||
      !form.value.gender ||
      !form.value.email ||
      !form.value.phone ||
      !this.address
    )
      return;

    if (form.value.pickup == false) {
      this.pickup = "0";
    } else {
      this.pickup = "1";
    }
    this.parentFormData = {
      email: form.value.email,
      personal_info: {
        first_name: form.value.first_name,
        last_name: "",
        middle_name: "",
        gender: form.value.gender,
        race: form.value.race,
        profile_image: this.image,
      },
      contact_info: {
        phone: form.value.phone,
        address1: this.address,
        zipcode: this.zip_code,
      },
      relation_id: form.value.relation_id,
      student_id: this.studentId,
      pickup: this.pickup,
      session_id: this.setup.session_id,
    };
    if (this.editMode) {
      this.studentService
        .editParent(this.parentId, this.parentFormData)
        .subscribe((res: any) => {
          if (res.status == true) {
            this.toster.success("Parent updated successfully");
            this.router.navigate([
              "manage-students/students/parent-list/",
              this.studentId,
              this.studentName,
            ]);
          } else {
            this.toster.error(res.message);
          }
        });
    } else {
      this.studentService
        .addParent(this.parentFormData)
        .subscribe((res: any) => {
          if (res.status == true) {
            this.toster.success("Parent added successfully");
            this.router.navigate([
              "manage-parent"]);
          } else {
            this.toster.error(res.message);
          }
        });
    }
  }

  cancel() {
    this.router.navigate([
      "manage-parent"]);
  }

  file(evt) {
    var files = evt.target.files;
    var file = files[0];

    if (files && file) {
      var reader = new FileReader();

      reader.onload = this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
    }
  }

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.image = "data:image/jpeg;base64," + btoa(binaryString);
    document.getElementById("profile_image").click();
  }
}
