import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import { StudentService } from "../../manage-students/student.service";
import swal from "sweetalert2";
import { PermissionService } from "../../shared/services/permission.service";
import { ParentServicesService } from "../parent-services.service";
@Component({
  selector: "app-list-parents",
  templateUrl: "./list-parents.component.html",
  styleUrls: ["./list-parents.component.scss"],
})
export class ListParentsComponent implements OnInit {
  studentName: any = "";
  userId: any = "";
  parentList: any = [];
  pickupValue: any = "0";
  allowed = {
    create: false,
    update: false,
    delete: false,
    import: false,
    export: false,
  };
  constructor(
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private permission: PermissionService,
    private studentService: StudentService,
    private parentService: ParentServicesService
  ) {}

  ngOnInit(): void {
    setInterval(() => {
      this.cdr.detectChanges();
    }, 100);
    console.log("in...")
    this.parentService.getParentFromStudent().subscribe((data) => {
      console.log(data);
      this.studentName = data.first_name + " " + data.last_name;
      this.userId = data.user_id;
      this.getParentList("0");
      this.getPermission();
    }, err=>{
      console.log(err)
    });
  }
  getPermission() {
    try {
      var moduleData = this.permission.permission.moduleData.filter(
        (item) => item.name === "StudentParents"
      );
      this.allowed.create = moduleData[0].permissions[2];
      this.allowed.update = moduleData[0].permissions[3];
      this.allowed.delete = moduleData[0].permissions[4];
    } catch (e) {
      setTimeout(() => {
        this.getPermission();
      }, 500);
    }
  }

  addParent() {
    this.router.navigate([
      "manage-parent/" +
        this.userId +
        "/" +
        this.studentName +
        "/" +
        "add-parent",
    ]);
  }
  editParent(parent_id) {
    this.router.navigate([
      "manage-parent/" +
        this.userId +
        "/" +
        this.studentName +
        "/" +
        "edit-parent/" +
        parent_id,
    ]);
  }


  onValueChange(event){
    if (event==false){
      this.pickupValue=0
    }
    else{
      this.pickupValue=1
    }
    this.getParentList(this.pickupValue)
   }
   
  getParentList(pickup: any) {

    this.studentService
      .getParentListById(this.userId, pickup)
      .subscribe((res: any) => {
        this.parentList = res.result;
      });
  }
  removeParent(id) {
    swal
      .fire({
        title: "Are you sure?",
        text: "To Delete Parent!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#2F8BE6",
        cancelButtonColor: "#F55252",
        confirmButtonText: "Sure",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      })
      .then((result) => {
        if (result.value) {
          this.studentService.parentDelete(id).subscribe((res: any) => {
            this.getParentList(this.pickupValue);
          });
        }
      });
  }
  goback() {
    this.location.back();
  }
}
