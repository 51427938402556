import { identifierModuleUrl } from '@angular/compiler';
import { Injectable } from '@angular/core';
import { ApiService } from '../shared/api/api.service';
import { SetupService } from 'app/setup/setup.service';
import { HttpClient,HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class StudentService {
selectedStudents = []
  constructor(private apiService: ApiService,private setup:SetupService,private http: HttpClient) { }
  sendMessage(data){
    return this.apiService.ApiPOST('user/message',data)
  }
  sendEmail(data){
    return this.apiService.ApiPOST('user/sendEmail',data)
  }
  deleteAdmission(id) {
    return this.apiService.ApiDELETE('/student/deleteAdmission/' + id)
  }

  generateParentPassword(data) {
    return this.apiService.ApiPOST('user/generateParentPassword', data)
  }

  generateStudentPassword(data) {
    return this.apiService.ApiPOST('user/generateStudentPassword', data)
  }

  editFollowUp(data) {
    return this.apiService.ApiPOST('followup/editFollowUp', data)
  }

  followUpDetailsById(id) {
    return this.apiService.ApiGET('followup/getFollowUp/' + id)
  }

  deleteFollowUp(id) {
    return this.apiService.ApiDELETE('/followUp/deleteFollowUp/' + id)
  }

  addFollowUpDetails(data) {
    return this.apiService.ApiPOST('followup/addFollowUp ', data)
  }

  followUpDetails(id) {
    return this.apiService.ApiGET('followup/getFollowUps/' + id)
  }

  addStudentSkills(data) {
    return this.apiService.ApiPOST('user/addUpdateStudentSkills', data)
  }

  addSkill(data) {
    return this.apiService.ApiPOST('skills/ addSkill', data)
  }

  updatePhysicalInfo(data) {
    return this.apiService.ApiPOST('user/updatePhysicalInfo ', data)
  }

  updateBasicInfo(data) {
    return this.apiService.ApiPOST('user/updateAdmin', data)
  }

  getBasicInfo(id) {
    return this.apiService.ApiGET('user/getStudent/' + id)
  }

  parentDelete(id) {
    return this.apiService.ApiDELETE('user/deleteAdmin/' + id)
  }

  getParentListById(id, pickup) {
    return this.apiService.ApiGET('user/getStudentParents/' + id + "/" + pickup)
  }

  addParent(data) {
    return this.apiService.ApiPOST('user/addParent', data)
  }

  editParent(id, data) {
    return this.apiService.ApiPUT(`user/editParent/${id}`, data)
  }

  deleteStudent(id) {
    return this.apiService.ApiDELETE('user/deleteAdmin/' + id)
  }

  parentList() {
    return this.apiService.ApiGET('user/getParents')
  }

  confirmStudent(data) {
    return this.apiService.ApiPOST('user/makeStudent', data)
  }

  changeStatus(data) {
    return this.apiService.ApiPOST('user/changeStatus', data)
  }

  activateUser(user_id) {
    return this.apiService.ApiPOST('user/activateUser', { id: user_id });
  }

  deactivateUser(user_id) {
    return this.apiService.ApiPOST('user/deactivateUser', { id: user_id });
  }

  sendReport(data){
    return this.apiService.ApiPOST('user/sendEmailReport',data);
  }

  studentList() {
  
    return this.apiService.ApiGET('user/getStudents'+"?sessionId="+this.setup.session_id)
  }

  getClassStudents(class_id) {
    return this.apiService.ApiGET(`user/get-class-students/${class_id}`);
  }

  addRelation(name, notes) {
    return this.apiService.ApiPOST('student/addRelation', { name, notes });
  }

  editRelation(id, name, notes) {
    return this.apiService.ApiPOST('student/editRelation', { id, name, notes });
  }

  getRelations() {
    return this.apiService.ApiGET('student/getRelations');
  }

  deleteRelation(id) {
    return this.apiService.ApiDELETE('student/deleteRelation/' + id);
  }

  sendSMS(message) {
    return this.apiService.ApiPOST('user/sendSMS', message);
  }

  sendEmailORSMS(message) {
    return this.apiService.ApiPOST('user/sendEmailORSMS', message);
  }

  getAllStudentAttendances() {
    return this.apiService.ApiGET('student-attendance/all');
  }

  getStudentAttendances(class_id, date) {
    return this.apiService.ApiGET(`student-attendance/${class_id}/${date}`);
  }

  getStudentAttendancesStartAndEndOfWeek(class_id, startdate, enddate) {
    return this.apiService.ApiGET(`student-attendance/${class_id}/${startdate}/${enddate}`);
  }

  getStudentAttendancesByClass(class_id) {
    return this.apiService.ApiGET(`student-attendance/class/${class_id}`);
  }

  getStudentAttendancesByDate(date) {
    return this.apiService.ApiGET(`student-attendance/date/${date}`);
  }

  attendClass(data) {
    return this.apiService.ApiPOST('student-attendance/add', data);
  }

  updateStudentAttendance(data) {
    return this.apiService.ApiPUT('student-attendance/update', data);
  }
  importStudent(data){
    return this.apiService.ApiPOST('student/importStudent',data);
  }

  getStudentsInClass(class_id) {
    return this.apiService.ApiGET('student/get-students-in-class/' + class_id);
  }

  getStudentsInClasses(classes) {
    return this.apiService.ApiPOST('student/get-students-in-classes', { classes });
  }

  joinClass(student_id, class_id) {
    return this.apiService.ApiPOST('student/join-class', { student_id, class_id });
  }

  leaveClass(student_id, class_id, reason = '') {
    return this.apiService.ApiPOST('student/leave-class', { student_id, class_id, reason });
  }

  getMyClasses(student_id) {
    return this.apiService.ApiGET('student/get-my-classes/' + student_id);
  }

  getCurrentClass(student_id) {
    return this.apiService.ApiGET('student/get-current-class/' + student_id);
  }

  checkStudentFree(student_id, class_id) {
    return this.apiService.ApiGET('student/check-student-free/' + student_id + '/' + class_id);
  }

}
