// export const environment = {
//   production: false,
//   siteUrl: 'http://localhost:4200/',
//   serverUrl: 'https://api.socratessmsserver.com/api',
//   socketUrl: 'https://api.socratessmsserver.com',
//   basePath: '',
//   videoPath: 'http://localhost:4200/video-conf',
//   urlEcommAdmin: 'http://localhost:4201/admin',
//   urlEcommWebsite: 'http://localhost:4201/',
//   urlHrModule: 'http://localhost:4201',
// }
export const environment = {
  production: true,
  siteUrl: 'https://www.socratessmsserver.com',
  //serverUrl: 'http://localhost:3800',
  serverUrl: 'https://api.socratessmsserver.com/api',
  socketUrl: 'wss://api.socratessmsserver.com:5000',
  basePath: '',
  videoPath: 'https://www.socratessmsserver.com/video-conf',
  urlEcommAdmin: 'https://ecommerce.socratessmsserver.com/admin',
  urlEcommWebsite: 'https://ecommerce.socratessmsserver.com/',
  urlHrModule: 'https://account.socratessmsserver.com/',
}