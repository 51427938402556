import { Injectable } from "@angular/core";
import { Subject, BehaviorSubject } from "rxjs";
import { ApiService } from '../api/api.service';
import { environment } from '../../../environments/environment';

export interface ITemplateConfig {
  layout: {
    variant: string;                   // options: Dark, Light & Transparent
    menuPosition: string;              // options: Side, Top (Note: Use 'Side' for Vertical Menu & 'Top' for Horizontal Menu )
    customizer: {
      hidden: boolean;               // options: true, false
    };
    navbar: {
      type: string;                     // options: Static & Fixed
    }
    sidebar: { //Options for Vertical Side menu
      collapsed: boolean;             // options: true, false
      size: string;                   // Options: 'sidebar-lg', 'sidebar-md', 'sidebar-sm'
      backgroundColor: string;        // Options: 'black', 'pomegranate', 'king-yna', 'ibiza-sunset', 'flickr', 'purple-bliss', 'man-of-steel', 'purple-love'

      /* If you want transparent layout add any of the following mentioned classes to backgroundColor of sidebar option :
        bg-glass-1, bg-glass-2, bg-glass-3, bg-glass-4, bg-hibiscus, bg-purple-pizzaz, bg-blue-lagoon, bg-electric-viloet, bg-protage, bg-tundora
      */
      backgroundImage: boolean;        // Options: true, false | Set true to show background image
      backgroundImageURL: string;
      logo_image: string,
      school_name: string
    }
  };
}

const _THEME = JSON.parse(localStorage.getItem('themeSetting'));
@Injectable({
  providedIn: "root"
})
export class ConfigService {
  public templateConf: ITemplateConfig = _THEME ? _THEME : {
    layout: {
      variant: "Dark",
      menuPosition: "Side",
      customizer: {
        hidden: true
      },
      navbar: {
        type: 'Static'
      },
      sidebar: {
        collapsed: false,
        size: "sidebar-md",
        backgroundColor: "black",
        backgroundImage: true,
        backgroundImageURL: "assets/img/Socrates_Logo1.png",
        logo_image: '',
        school_name: 'thunder'
      }
    }
  };
  templateConfSubject = new BehaviorSubject<ITemplateConfig>(this.templateConf);
  templateConf$ = this.templateConfSubject.asObservable();

  constructor(private apiService: ApiService) {
    this.setConfigValue();
  }

  // Default configurations for Light layout. Please check *customizer.service.ts* for different colors and bg images options

  setConfigValue() {
    this.apiService.ApiGET('get-template-conf').subscribe((res: any) => {
      if (res.status && res.result) {
        let conf = res.result;
        this.templateConf = Object.assign(this.templateConf, conf);
        this.templateConfSubject.next(this.templateConf);
        localStorage.setItem('themeSetting', JSON.stringify(conf));
      }
      else {
        let config = localStorage.getItem('themeSetting');
        if (config) {
          this.templateConf = Object.assign(this.templateConf, config);
          this.templateConfSubject.next(this.templateConf);
        }
      }
    })
    // let config = localStorage.getItem('themeSetting');

    // if (config) {
    //   let c = JSON.parse(config);
    //   sidebar_setting.collapsed = c.collapsed;
    //   sidebar_setting.size = c.size;
    //   sidebar_setting.backgroundColor = c.backgroundColor;
    //   sidebar_setting.backgroundImage = c.backgroundImage;
    //   sidebar_setting.backgroundImageURL = c.backgroundImageURL;
    // }

  }

  // Default configurations for Dark layout. Please check *customizer.service.ts* for different colors and bg images options

  // setConfigValue() {
  //   this.templateConf = {
  //     layout: {
  //       variant: "Dark",
  //       menuPosition: "Side",
  //       customizer: {
  //         hidden: true
  //       },
  //       navbar: {
  //         type: 'Static'
  //       },
  //       sidebar: {
  //         collapsed: false,
  //         size: "sidebar-md",
  //         backgroundColor: "black",
  //         backgroundImage: true,
  //         backgroundImageURL: "assets/img/sidebar-bg/01.jpg"
  //       }
  //     }
  //   };
  // }

  // Default configurations for Transparent layout. Please check *customizer.service.ts* for different colors and bg images options

  // setConfigValue() {
  //   this.templateConf = {
  //     layout: {
  //       variant: "Transparent",
  //       menuPosition: "Side",
  //       customizer: {
  //         hidden: true
  //       },
  //       navbar: {
  //         type: 'Static'
  //       },
  //       sidebar: {
  //         collapsed: false,
  //         size: "sidebar-md",
  //         backgroundColor: "bg-glass-1",
  //         backgroundImage: true,
  //         backgroundImageURL: ""
  //       }
  //     }
  //   };
  // }


  async applyTemplateConfigChange(tempConfig: ITemplateConfig) {
    this.templateConf = Object.assign(this.templateConf, tempConfig);
    this.templateConfSubject.next(this.templateConf);
    await this.apiService.ApiPOST('set-template-conf', { template_conf: tempConfig }).toPromise();
    localStorage.setItem('themeSetting', JSON.stringify(tempConfig));
  }

}
