import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})

export class PaymentService {
  public items = []

  setItem (param) {
    this.items.push(param)
  }

  getItem () {
    return this.items
  }

  clearItem () {
    this.items = []
  }
}
