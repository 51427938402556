import { Injectable } from '@angular/core';
import { ApiService } from 'app/shared/api/api.service';
@Injectable({
  providedIn: 'root'
})
export class ParentServicesService {

  constructor(
    private apiService:ApiService
  ) { }
    getParentFromStudent(){
      return this.apiService.ApiGET('user/getStudentFromParent')
    }
}
