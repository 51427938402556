import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ApiService } from "../../api/api.service";

@Component({
  selector: "app-modal-reply",
  templateUrl: "./modal-reply.component.html",
  styleUrls: ["./modal-reply.component.scss"],
})
export class ModalReplyComponent implements OnInit {

  messageChat:any;
  @Input() type: any;
  @Input() to: any = '';
  body: any;
  subject: any;
  constructor(
    public activeModal: NgbActiveModal,
    public apiService: ApiService
  ) {}

  ngOnInit(): void {
 
  }

  closeModal(){
    this.activeModal.dismiss('Cross click');
  }

  sendMail() {
      let reqBody = {
        to: this.to,
        subject: this.subject,
        body: this.body,
      }

      this.apiService
		  .ApiPOST("user/sendReplyMail", reqBody)
		  .subscribe((res: any) => {
			  console.log(res);
        this.activeModal.dismiss();
		  });
      console.log(reqBody);
  }
  
}
