import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { SetupService } from "app/setup/setup.service";
import { AuthService } from "app/shared/auth/auth.service";
@Component({
  selector: "app-modal-popup",
  templateUrl: "./modal-popup.component.html",
  styleUrls: ["./modal-popup.component.scss"],
})
export class ModalPopupComponent implements OnInit {
  time = 60;
  @Input() name;
  constructor(
    public activeModal: NgbActiveModal,
    private setup: SetupService,
    private auth: AuthService
  ) {}

  ngOnInit(): void {
    setInterval(() => {
      this.time--;
      if(this.time<1){
        this.activeModal.dismiss();  
      }
    }, 1000);
  }
  extendSession() {
    this.setup.preventLogout();
    this.activeModal.dismiss();
  }
  logout() {
    this.setup.preventLogout()
    this.auth.logout();
    this.activeModal.dismiss();
  }
}
