import {
    Component,
    OnInit,
    ViewChild,
    OnDestroy,
    ElementRef,
    AfterViewInit,
    ChangeDetectorRef,
    HostListener,
} from "@angular/core";


import { NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { customAnimations } from "../animations/custom-animations";
import { DeviceDetectorService } from "ngx-device-detector";
import { ConfigService } from "../services/config.service";
import { Subscription } from "rxjs";
import { LayoutService } from "../services/layout.service";
import { PermissionService } from "../services/permission.service";
import { SetupService } from "app/setup/setup.service";
import { InteractionService } from "app/shared/services/interaction.service";
import { ApiService } from "../api/api.service";

import { ROUTES } from "./vertical-menu-routes.config";
import { HROUTES } from "../horizontal-menu/navigation-routes.config";

declare var $: any;

@Component({
    selector: "app-sidebar",
    templateUrl: "./vertical-menu.component.html",
    styleUrls: ["./vertical-menu.component.scss"],
    animations: customAnimations,
})
export class VerticalMenuComponent implements OnInit, OnDestroy {
    @ViewChild("toggleIcon") toggleIcon: ElementRef;
    public menuItems: any[];
    public amenuItems: any[];
    level = 0;
    logoUrl = "assets/img/logo.png";
    schoolName = "Thunder";
    public config: any = {};
    protected innerWidth: any;
    layoutSub: Subscription;
    configSub: Subscription;
    perfectScrollbarEnable = true;
    collapseSidebar = false;
    resizeTimeout;
    editbackground = false;
    permission: any[] = [];
    roleWeight = -1;
    roleName = "";
    sideBarMenu = {
        default: true,
        chat: false,
    };
    chatUsers: any;
    yourId:any;
    constructor(
        private router: Router,
        private interactionService: InteractionService,
        public translate: TranslateService,
        private layoutService: LayoutService,
        private configService: ConfigService,
        private cdr: ChangeDetectorRef,
        private deviceService: DeviceDetectorService,
        private permissionService: PermissionService,
        private setup: SetupService,
        private apiService: ApiService
    ) {
  
        this.config = this.configService.templateConf;
        this.innerWidth = window.innerWidth;
        this.isTouchDevice();
        this.apiService.mySubject.subscribe(data=>{
          if(data == 'chat'){
            this.sideBarMenu.chat = true;
            this.sideBarMenu.default = false;
            this.getChatUsers();
          }else{
            this.sideBarMenu.chat = false;
            this.sideBarMenu.default = true;
          }
        })
        this.yourId = localStorage.getItem('user_id')
    }
    getChatUsers() {
        this.apiService.ApiGET("chat/getUsers").subscribe((res) => {
            this.chatUsers = res.allUsers;
        });
    }
    setActive(data){
      this.apiService.activeChat.next(data)
    }
    ngOnInit() {

        
        
        setInterval(() => {
            this.cdr.detectChanges();
        }, 100);
                
        this.interactionService.permissions.subscribe((data) => {
            if (data) {
                this.permission = data.moduleData;
                this.roleWeight = data.weight;
                this.roleName = data.name;
                this.filterMenus();
            }
        });

        this.configSub = this.configService.templateConf$.subscribe(
            (templateConf) => {
                if (templateConf) {
                    this.config = templateConf;
                }
                this.loadLayout();
                this.cdr.markForCheck();
            }
        );

        this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
            (collapse) => {
                // console.log(this.config);
                if (this.config.layout.menuPosition === "Side") {
                    this.collapseSidebar = collapse;
                }
            }
        );
    }

    filterMenus() {
        /*
        function replaceWordInObject(obj, wordToReplace, replacementWord) {
            if (typeof obj === 'string') {
              // If the current value is a string, replace the word using the 'replace()' method
              return obj.replace(new RegExp(wordToReplace, 'gi'), replacementWord);
            } else if (Array.isArray(obj)) {
              // If the current value is an array, recursively process each element
              return obj.map((item) => replaceWordInObject(item, wordToReplace, replacementWord));
            } else if (typeof obj === 'object' && obj !== null) {
              // If the current value is an object, recursively process each property
              const newObj = {};
              for (const key in obj) {
                newObj[key] = replaceWordInObject(obj[key], wordToReplace, replacementWord);
              }
              return newObj;
            } else {
              // For other types (e.g., numbers, booleans), return as-is
              return obj;
            }
          }
          
        // console.log(ROUTES);
        var orginMenus = ROUTES;

        orginMenus = replaceWordInObject(orginMenus, "/null", '/'+localStorage.getItem("thunder_token"));
        // this.menuItems = orginMenus
        // return
        const newItem = [];
        for (let i = 0; i < orginMenus.length; i++) {
            const menu1 = orginMenus[i];
            // console.log(menu1);
            if (menu1.ignoreRoles.includes(this.roleWeight)) {
                continue;
            }
            if (this.checkModulePermission(menu1) === false) {
                continue;
            }
            if (menu1.submenu.length > 0) {
                const newMenu1 = { ...menu1, ...{ submenu: [] } };

                for (let j = 0; j < menu1.submenu.length; j++) {
                    const subItem = menu1.submenu[j];
                    if (this.checkModulePermission(subItem) === false) {
                        continue;
                    }

                    // if(this.roleWeight > 4){
                    //     newMenu1.isClient = true;
                    // }
                    // alert(localStorage.getItem("thunder_token"));
                    // subItem.path.replace('/null', '/'+localStorage.getItem("thunder_token"));
                    // menu1.submenu[j].path.replace('/null', '/'+localStorage.getItem("thunder_token"));
                    if (subItem.submenu.length > 0) {
                        const newsubItem = { ...subItem, ...{ submenu: [] } };
                        for (let k = 0; k < subItem.submenu.length; k++) {
                            if (
                                this.checkModulePermission(
                                    subItem.submenu[k]
                                ) === false &&
                                (this.roleWeight > 3 ||
                                    (this.roleWeight < 4 &&
                                        subItem.submenu[k].path === ""))
                            ) {
                                continue;
                            }
                            newsubItem.submenu.push(subItem.submenu[k]);
                        }
                        newMenu1.submenu.push(newsubItem);
                    } else {
                        newMenu1.submenu.push(subItem);
                    }
                }
                if (newMenu1.path !== "" || newMenu1.submenu.length > 0) {
                    newItem.push(newMenu1);
                }
            } else {
                if (menu1.path !== "" || menu1.submenu.length > 0) {
                    newItem.push(menu1);
                }
            }
        }
        */
        let filteredData = [];
            
        function replaceWordInObject(obj, wordToReplace, replacementWord) {
            if (typeof obj === 'string') {
              // If the current value is a string, replace the word using the 'replace()' method
              return obj.replace(new RegExp(wordToReplace, 'gi'), replacementWord);
            } else if (Array.isArray(obj)) {
              // If the current value is an array, recursively process each element
              return obj.map((item) => replaceWordInObject(item, wordToReplace, replacementWord));
            } else if (typeof obj === 'object' && obj !== null) {
              // If the current value is an object, recursively process each property
              const newObj = {};
              for (const key in obj) {
                newObj[key] = replaceWordInObject(obj[key], wordToReplace, replacementWord);
              }
              return newObj;
            } else {
              // For other types (e.g., numbers, booleans), return as-is
              return obj;
            }
        }

        const permissionTitles = this.permission.filter(res=>res.value).map(permission => permission.name.toLowerCase());
        
        let orginMenus = ROUTES;
        
        orginMenus = replaceWordInObject(orginMenus, "/null", '/'+localStorage.getItem("thunder_token"));
    
        filteredData = this.filterMenuItems(orginMenus, permissionTitles);
      
      
        this.menuItems = filteredData;
    }

    filterMenuItems(menuItems, permissionTitles) {
        const filteredData = [];
        
        for (const item of menuItems) {
            const hasSubmenu = item.submenu && item.submenu.length > 0;
            if (hasSubmenu) {
                const filteredSubmenu = this.filterMenuItems(item.submenu, permissionTitles);
                if (filteredSubmenu.length > 0) {
                    const newItem = { ...item, submenu: filteredSubmenu };
                    filteredData.push(newItem);
                }
            } else if (permissionTitles.includes(item.module.toLowerCase()) || item.showDefault) {
                filteredData.push(item);
            }
        }
        
        return filteredData;
    }



    checkModulePermission(menu) {
        if (menu.title === "HR") {
            if (this.roleName !== "District staff" && this.roleName !== "Staff")
                return false;
        }
        if (menu.module === "") {
            return true;
        }
        if (menu.specRoles.includes(this.roleWeight)) {
            return true;
        }
        if (menu.ignoreRoles.includes(this.roleWeight)) {
            return false;
        } else if (menu.isCommon) {
            return true;
        }
        let flag = false;
        for (let i = 0; i < this.permission.length; i++) {
            if (
                this.permission[i].name.toLowerCase() ===
                menu.module.toLowerCase()
            ) {
                flag = this.permission[i].value;

                break;
            }
        }
        return flag;
    }

    @HostListener("window:resize", ["$event"])
    onWindowResize(event) {
        if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout);
        }
        this.resizeTimeout = setTimeout(
            (() => {
                this.innerWidth = event.target.innerWidth;
                this.loadLayout();
            }).bind(this),
            500
        );
    }

    loadLayout() {
        this.filterMenus();
        
        if (this.config.layout.sidebar.logo_image) {
            this.logoUrl = this.config.layout.sidebar.logo_image;
        } else {
            this.logoUrl = "assets/img/logo.png";
        }
        
        if (this.config.layout.sidebar.school_name) {
            this.schoolName = this.config.layout.sidebar.school_name;
        } else {
            this.schoolName = "Thunder";
        }

        if (this.config.layout.sidebar.collapsed) {
            this.collapseSidebar = true;
        } else {
            this.collapseSidebar = false;
        }
    }

    editBackground() {
        document.getElementById("edit-background").classList.remove("d-none");
        document
            .getElementById("sidebar-black-background")
            .classList.remove("d-none");
        this.editbackground = true;
    }

    toggleSidebar() {
        let conf = this.config;
        conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
        this.configService.applyTemplateConfigChange({ layout: conf.layout });

        setTimeout(() => {
            this.fireRefreshEventOnWindow();
        }, 300);
    }

    closeEditBackground() {
        this.editbackground = false;
        document.getElementById("edit-background").classList.add("d-none");
        document
            .getElementById("sidebar-black-background")
            .classList.add("d-none");
    }

    goEditBackground() {
        this.editbackground = false;
        document.getElementById("edit-background").classList.add("d-none");
        document
            .getElementById("sidebar-black-background")
            .classList.add("d-none");
        this.router.navigate(["/setup/edit-background"]);
    }

    goEditLogo() {
        this.editbackground = false;
        document.getElementById("edit-background").classList.add("d-none");
        document
            .getElementById("sidebar-black-background")
            .classList.add("d-none");
        this.router.navigate(["/setup/edit-logo"]);
    }

    fireRefreshEventOnWindow = function () {
        const evt = document.createEvent("HTMLEvents");
        evt.initEvent("resize", true, false);
        window.dispatchEvent(evt);
    };

    CloseSidebar() {
        this.layoutService.toggleSidebarSmallScreen(false);
    }

    isTouchDevice() {
        const isMobile = this.deviceService.isMobile();
        const isTablet = this.deviceService.isTablet();

        if (isMobile || isTablet) {
            this.perfectScrollbarEnable = false;
        } else {
            this.perfectScrollbarEnable = true;
        }
    }

    ngOnDestroy() {
        if (this.layoutSub) {
            this.layoutSub.unsubscribe();
        }
        if (this.configSub) {
            this.configSub.unsubscribe();
        }
    }
}
