import { Injectable } from "@angular/core";
import { ApiService } from "../shared/api/api.service";
import { InteractionService } from "app/shared/services/interaction.service";
import { BehaviorSubject, Subject, interval } from "rxjs";
import { AuthService } from "app/shared/auth/auth.service";

@Injectable({
	providedIn: "root",
})
export class SetupService {
	medicalImmunizations: any = [
		{ id: 1, itemName: "Immunization_1" },
		{ id: 2, itemName: "Immunization_2" },
	];

	medicalAllergies: any = [
		{ id: 1, name: "Water" },
		{ id: 2, name: "Skin Allergies" },
	];

	sources: any = [
		{ id: 1, name: "Phone Book" },
		{ id: 2, name: "Google" },
		{ id: 3, name: "Our Website" },
	];

	inquiry_statuses: any = [
		{ id: 1, name: "Warm" },
		{ id: 2, name: "Cold" },
		{ id: 3, name: "Hot" },
	];
	country: any = [];
	dataChanged = new Subject<any>();
	attendance = new BehaviorSubject<any>(null);
	session_id: any = "";
	academic_id: any = "";
	trackerData = new Subject<any>();
	timerData: any;
	userData = {
		_id: "",
		role_id: "",
		role_weight: "",
		email: "",
		user_id: "",
		personal_info: {
			first_name: "",
			last_name: "",
			profile_image: "",
		},
	};
	triggerSubject = new Subject<void>();
	timeout: any;
	schoolId: any;
	managementType: any;
	credentials: any;
	
	constructor(private apiService: ApiService, private interactionService: InteractionService, private auth: AuthService) {
		
		const intervalId = setInterval(() => {
			
			if (this.userData._id != "" && localStorage.getItem("user_id") && this.session_id != "") {
				this.updateSession(this.userData._id, this.session_id).subscribe((res) => {
					this.timerData = res.timeData;
					this.trackerData.next(res.timeData);
				});
				clearInterval(intervalId);
			}
		}, 10);
		interval(570000).subscribe(() => {
			if (localStorage.getItem("user_id")) {
				this.updateSession(this.userData._id, this.session_id).subscribe((res) => {
					this.timerData = res.timeData;
					this.trackerData.next(res.timeData);
				});
			}
		});
		interval(3600000).subscribe(() => {
			this.triggerFunction();
		});
	}
	//Payment Credentials
	getSchoolPaymentCredentials(data) {
		return this.apiService.ApiGET("/payment-management/getUserCredentials?userId=" + data);
	}
	addSchoolPaymentCredentials(data) {
		return this.apiService.ApiPOST("/payment-management/addCredentials", data);
	}
	updateSchoolPaymentCredentials(data) {
		return this.apiService.ApiPOST("/payment-management/updateCredentials", data);//------------------!//<>
	}
	makepaymentrequest() {
		return this.apiService.ApiGET("/payment-management/makePageRequest");//------------------!//<>
	}
	makeCreditCardPayment(data) {
		return this.apiService.ApiPOST("/payment/makeCreditCardPayment", data); //-----!//>
	}
	makeAccountPayment(data) {
		return this.apiService.ApiPOST("/payment/makeDebitAccountTransaction", data);//------------------!//<>
	}

	// tracker loging start
	getDataForLog(data) {
		return this.apiService.ApiGET("/tracker/getLogingData?userId=" + data);
	}

	getUserLogData(userId) {
		return this.apiService.ApiGET("/tracker/getUserLogData?userId=" + userId + "&sessionId=" + this.session_id);
	}

	// managing credentials
	getEmailCredentials(data) {
		return this.apiService.ApiGET("/credential/getEmail?userId=" + data);
	}
	setEmailCredentials(data) {
		return this.apiService.ApiPOST("/credential/addEmail", data);
	}
	setSmsCredentials(data) {
		return this.apiService.ApiPOST("/credential/addSmsDetails", data);
	}
	// tracker loging stop

	triggerFunction() {
		//5 is student role
		if(Number(this.userData.role_weight) == 5){
			this.timeout = setTimeout(() => {
				this.auth.logout();
			}, 60000);
			this.triggerSubject.next();
		}
	}
	preventLogout() {
		clearTimeout(this.timeout);
		return;
	}
	updateSession(userId, session) {
		return this.apiService.ApiGET(`tracker/update-session?userId=${userId}&sessionId=${session}`);
	}
	getObservableSession(sessionId) {
		this.session_id = sessionId;
		this.dataChanged.next(this.session_id);
	}
	getSeeAttendance(data) {
		this.attendance.next(data);
	}
	getClonedClasses() {
		return this.apiService.ApiGET("classes/getClonedClasses");
	}
	cloneClass(data) {
		return this.apiService.ApiPOST("classes/cloneClass", data);
	}
	getClonedAssignment() {
		return this.apiService.ApiGET("assignments/getClonedAssignments");
	}
	getClonedHomework() {
		return this.apiService.ApiGET("homework/getClonedHomeworks");
	}
	getClassByGrade(data) {
		return this.apiService.ApiPOST("classes/getClassesByGrade", data);
	}
	assignStudentsToClass(data) {
		return this.apiService.ApiPOST("setup/assignStudentsToClass", data);
	}
	setWaitingStudentsList(data) {
		return this.apiService.ApiPOST("setup/waitingList", data);
	}
	getWaitingStudentsList(class_id) {
		return this.apiService.ApiGET("setup/waitingList?class_id=" + class_id);
	}
	removeFromWaitingList(class_id, student_id) {
		return this.apiService.ApiPOST("setup/removeFromWaitingList", { class_id, student_id });
	}
	studentByGrade(id) {
		return this.apiService.ApiPOST("setup/getAllStudentsUnassignedAndAssigned", id);
	}

	getClassesBySubject(id) {
		return this.apiService.ApiPOST("setup/getClassesBySubject", id);
	}
	subjectByGrade(id) {
		return this.apiService.ApiPOST("setup/getSubjectsByGrade", id);
	}
	getSkills() {
		return this.apiService.ApiGET("skills/getSkills");
	}
	deleteSkill(id) {
		return this.apiService.ApiDELETE("skills/deleteSkill/" + id);
	}
	addSkill(data) {
		return this.apiService.ApiPOST("skills/addSkill ", data);
	}
	addMoreSkills(data) {
		return this.apiService.ApiPOST("skills/addMoreSkills ", data);
	}
	editAdmission(data) {
		return this.apiService.ApiPOST("student/updateAdmission", data);
	}
	admissionById(id) {
		return this.apiService.ApiGET("student/getAdmission/" + id);
	}
	addCity(data) {
		return this.apiService.ApiPOST("setup/addCity", data);
	}
	deleteCity(id) {
		return this.apiService.ApiDELETE("setup/deleteCity/" + id);
	}
	getCities(id) {
		return this.apiService.ApiGET("setup/getCities/" + id);
	}
	deleteState(id) {
		return this.apiService.ApiDELETE("setup/deleteState/" + id);
	}
	getState(countryId) {
		return this.apiService.ApiGET("setup/getStates/" + countryId);
	}
	addState(data) {
		return this.apiService.ApiPOST("setup/addState", data);
	}
	deleteCountry(id) {
		return this.apiService.ApiDELETE("setup/deleteCountry/" + id);
	}
	getCountries() {
		return this.apiService.ApiGET("setup/getCountries");
	}
	addCountry(data) {
		return this.apiService.ApiPOST("setup/addCountry", data);
	}
	editClass(data) {
		return this.apiService.ApiPOST("classes/editClass", data);
	}
	getClassById(id) {
		return this.apiService.ApiGET("classes/getClass/" + id);
	}
	deleteClass(id) {
		return this.apiService.ApiDELETE("classes/deleteClass/" + id);
	}
	deleteResetDatabase(id) {
		return this.apiService.ApiDELETE("setup/deleteResetDatabase/" + id);
	}
	getClasses() {
		return this.apiService.ApiGET("classes/getClasses");
	}
	getInteractionClasses() {
		return this.interactionService.classes;
	}
	createClass(data) {
		return this.apiService.ApiPOST("classes/addClass", data);
	}
	getClassesByLocationId(data) {
		return this.apiService.ApiPOST("classes/getClassesByLocationId", data);
	}
	importSchoolSessions(data) {
		return this.apiService.ApiPOST("setup/importSchoolSessions", data);
	}
	importDatabase(data) {
		return this.apiService.ApiPOST("setup/importDatabase", data);
	}
	resetDatabase() {
		return this.apiService.ApiDELETE("setup/resetDB");
	}
	editRoom(data) {
		return this.apiService.ApiPOST("setup/editRoom", data);
	}
	getRoomDetail(id) {
		return this.apiService.ApiGET("setup/getRoom/" + id);
	}
	getResetDatabase() {
		return this.apiService.ApiGET("setup/getResetDatabase");
	}
	deleteroom(id) {
		return this.apiService.ApiDELETE("setup/deleteRoom/" + id);
	}
	addRoom(form) {
		return this.apiService.ApiPOST("setup/addRoom", form);
	}
	getRooms(location_id) {
		return this.apiService.ApiPOST("setup/getRooms", { location_id });
	}
	getAllRooms() {
		return this.apiService.ApiGET("setup/getRooms");
	}
	deleteLocation(id) {
		return this.apiService.ApiDELETE("locations/deleteLocation/" + id);
	}
	updateLocation(data) {
		return this.apiService.ApiPOST("locations/editLocation", data);
	}
	getLocationList() {
		return this.apiService.ApiGET("locations/getLocations");
	}
	getLocation(id) {
		return this.apiService.ApiGET("locations/getLocation/" + id);
	}

	getRoomsCapacityByLocationID(id) {
		return this.apiService.ApiGET("setup/getRoomsCapacityByLocationID/" + id);
	}
	addLocation(data) {
		return this.apiService.ApiPOST("locations/addLocation", data);
	}
	addAcademicYear(name, start_date, end_date) {
		return this.apiService.ApiPOST("setup/addAcademicYear", {
			name,
			start_date,
			end_date,
		});
	}

	editAcademicYear(id, name, start_date, end_date) {
		return this.apiService.ApiPOST("setup/editAcademicYear", {
			id,
			name,
			start_date,
			end_date,
		});
	}

	getAcademicYear() {
		return this.apiService.ApiGET("setup/getAcademicYear");
	}

	deleteAcademicYear(id) {
		return this.apiService.ApiDELETE("setup/deleteAcademicYear/" + id);
	}

	addSession(name, academic_year_id, start_date, end_date, is_default, notes) {
		return this.apiService.ApiPOST("setup/addSession", {
			name,
			academic_year_id,
			start_date,
			end_date,
			is_default,
			notes,
		});
	}

	editSession(id, name, academic_year_id, start_date, end_date, is_default, notes) {
		return this.apiService.ApiPOST("setup/editSession", {
			id,
			name,
			academic_year_id,
			start_date,
			end_date,
			is_default,
			notes,
		});
	}

	getSessions() {
		return this.apiService.ApiGET("setup/getSessions");
	}

	changeSessionStatus(id) {
		return this.apiService.ApiGET("setup/changeSessionStatus/" + id);
	}

	deleteSession(id) {
		return this.apiService.ApiDELETE("setup/deleteSession/" + id);
	}

	addSchoolGrades(name, position, notes) {
		return this.apiService.ApiPOST("setup/addSchoolGrades", {
			name,
			position,
			notes,
		});
	}

	editSchoolGrades(id, name, position, notes) {
		return this.apiService.ApiPOST("setup/editSchoolGrades", {
			id,
			name,
			position,
			notes,
		});
	}

	getSchoolGrades() {
		return this.apiService.ApiGET("setup/getSchoolGrades");
	}
	getSchoolTeacher() {
		return this.apiService.ApiGET("/user/getTeachers");
	}

	deleteSchoolGrades(id) {
		return this.apiService.ApiDELETE("setup/deleteSchoolGrades/" + id);
	}

	addSubjectType(name, notes) {
		return this.apiService.ApiPOST("setup/addSubjectType", { name, notes });
	}

	editSubjectType(id, name, notes) {
		return this.apiService.ApiPOST("setup/editSubjectType", {
			id,
			name,
			notes,
		});
	}

	getSubjectType() {
		return this.apiService.ApiGET("setup/getSubjectType");
	}

	deleteSubjectType(id) {
		return this.apiService.ApiDELETE("setup/deleteSubjectType/" + id);
	}

	addDepartment(name, description) {
		return this.apiService.ApiPOST("setup/addDepartment", {
			name,
			description,
		});
	}

	editDepartment(id, name, description) {
		return this.apiService.ApiPOST("setup/editDepartment", {
			id,
			name,
			description,
		});
	}

	getDepartment() {
		return this.apiService.ApiGET("setup/getDepartment");
	}

	deleteDepartment(id) {
		return this.apiService.ApiDELETE("setup/deleteDepartment/" + id);
	}

	addSubject(data) {
		return this.apiService.ApiPOST("setup/addSubject", data);
	}

	editSubject(data) {
		return this.apiService.ApiPOST("setup/editSubject", data);
	}

	getSubjects() {
		return this.apiService.ApiGET("setup/getSubjects");
	}

	deleteSubject(id) {
		return this.apiService.ApiDELETE("setup/deleteSubject/" + id);
	}

	addSource(title, notes) {
		return this.apiService.ApiPOST("setup/addSource", { title, notes });
	}

	editSource(id, title, notes) {
		return this.apiService.ApiPOST("setup/editSource", { id, title, notes });
	}

	getSource() {
		return this.apiService.ApiGET("setup/getSource");
	}

	deleteSource(id) {
		return this.apiService.ApiDELETE("setup/deleteSource/" + id);
	}

	getSources() {
		return this.apiService.ApiGET("setup/getSources");
	}

	updatePreGradeSelection(data) {
		return this.apiService.ApiPOST("setup/updatePreGradeSelection", {
			data: data,
		});
	}

	getPreGradeSelection() {
		return this.apiService.ApiGET("setup/getPreGradeSelection");
	}

	addAdmission(data) {
		return this.apiService.ApiPOST("student/addAdmission", data);
	}
	getAdmissions(confirm) {
		return this.apiService.ApiGET("student/getAdmissions/" + confirm);
	}
	getImmunizations() {
		return this.apiService.ApiGET("setup/getImmunizations");
	}
	addImmunization(data) {
		return this.apiService.ApiPOST("setup/addImmunization", data);
	}
	addMoreImmunizations(data) {
		return this.apiService.ApiPOST("setup/addMoreImmunization", data);
	}
	deleteimmunization(id) {
		return this.apiService.ApiDELETE("setup/deleteImmunization/" + id);
	}

	deleteAllergy(id) {
		return this.apiService.ApiDELETE("setup/deleteAllergy/" + id);
	}

	getAllergies() {
		return this.apiService.ApiGET("setup/getAllergies");
	}
	addAllergy(data) {
		return this.apiService.ApiPOST("setup/addAllergy", data);
	}

	addMoreAllergy(data) {
		return this.apiService.ApiPOST("setup/addMoreAllergy ", data);
	}

	addSources(data) {
		return this.apiService.ApiPOST("setup/addSources", data);
	}
}
