import { Injectable } from '@angular/core';
import { ApiService } from '../api/api.service';
import { InteractionService } from './interaction.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  public permission: any;

  constructor(
    private apiService: ApiService,
    private interactionService: InteractionService
  ) { 
    this.interactionService.permissions.subscribe((permissions: any) => {
      if (permissions) {
        this.permission = permissions;
      }
    });
  }

  loadPermission() {
    return new Promise((resolve) => {
      this.apiService.ApiGET('user/loadPermission').subscribe((res: any) => {
        this.permission = res.result;
        resolve(this.permission);
      })
    })
  }

}
