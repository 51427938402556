import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, AfterViewChecked } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Socket } from 'ngx-socket-io';
import { SetupService } from 'app/setup/setup.service';
import { ApiService } from 'app/shared/api/api.service';

const ICE_SERVERS = [
  { urls: "stun:stun.l.google.com:19302" },
  { urls: "stun:stun.stunprotocol.org:3478" },
  { urls: "stun:stun.sipnet.net:3478" },
  { urls: "stun:stun.ideasip.com:3478" },
  { urls: "stun:stun.iptel.org:3478" },
  { urls: "turn:numb.viagenie.ca", username: "imvasanthv@gmail.com", credential: "d0ntuseme" },
  {
    urls: [
      "turn:173.194.72.127:19305?transport=udp",
      "turn:[2404:6800:4008:C01::7F]:19305?transport=udp",
      "turn:173.194.72.127:443?transport=tcp",
      "turn:[2404:6800:4008:C01::7F]:443?transport=tcp",
    ],
    username: "CKjCuLwFEgahxNRjuTAYzc/s6OMT",
    credential: "u1SQDR/SQsPQIxXNWQT7czc/G4c=",
  },
];

@Component({
  selector: 'app-videochat',
  templateUrl: './videochat.component.html',
  styleUrls: ['./videochat.component.scss']
})
export class VideochatComponent implements OnInit, AfterViewChecked {
  @ViewChild('videochat') public videoChatElem: ElementRef<any>;
  @ViewChild('chat_tab', { read: ElementRef }) public chat_tab: ElementRef<any>;
  @ViewChild('chat_input') public chat_input: ElementRef<any>;

  mode = 'chat'
  title = 'Instant Messanger'
  messages = []
  selfMsg = ''
  user = {
    user_id: '',
    username: '',
    avatar: 'https://i.gifer.com/no.gif',
    room: 'test'
  }
  can_chat = false
  localMediaStream = null
  room = null
  connects = {}
  userlist = []
  videoAvailable = true
  audioAvailable = true
  screenAvailable = true
  video = true;
  audio = true
  full_screen = false
  videos = {}
  user_avatars = {}

  constructor(
    private apiService: ApiService,
    private socket: Socket,
    private setup: SetupService,
    private route: ActivatedRoute,
    private router: Router,
    private chr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    setInterval(() => {
      this.chr.detectChanges();
    }, 100);
    this.mode = this.route.snapshot.paramMap.get('mode')
    this.room = this.route.snapshot.paramMap.get('room')
    this.loadChatHistory(this.room)
    this.joinRoom()
    if (this.mode === 'video') {
      this.title = 'Video Meeting'
      this.initVideo()
    }
    if (this.mode === 'audio') {
      this.title = 'Audio Meeting'
    }
    this.initSocket()

    const update_appointment = { completed: 1 };
    this.apiService.ApiPUT(`appointment/${this.room}`, update_appointment).subscribe((res: any) => {
      if (!res.status) console.log(res)
    })
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  scrollToBottom(): void {
    try {
      this.chat_tab.nativeElement.scrollTop = this.chat_tab.nativeElement.scrollHeight;
    } catch (err) { }
  }

  loadChatHistory(room_id) {
    this.apiService.ApiGET(`chathistory/${room_id}`).subscribe((res: any) => {
      if (res.status) {
        this.messages = res.result
        this.scrollToBottom()
        this.chr.detectChanges();
      }
    });
  }

  async joinRoom() {
    try {
      const user_profile = await this.apiService.ApiGET('').toPromise()
      const user = user_profile.result
      this.user.user_id = user.user_id
      this.user.username = user.personal_info?.first_name + ' ' + user.personal_info?.last_name
      this.user.avatar = user.personal_info?.profile_image
      this.user_avatars[this.user.user_id] = this.user.avatar
      this.user.room = this.room ? this.room : 'test'
      if (this.user.user_id) {
        this.socket.emit('join', this.user)
      } else {
        setTimeout(() => {
          this.joinRoom()
        }, 10000)
      }
    } catch (e) {
      console.log(e)
    }
  }

  initSocket() {
    this.socket.fromEvent('message').subscribe((data: any) => {
      data.time = (new Date(data.time)).toUTCString()
      this.messages.push(data)
      this.scrollToBottom()
    });

    this.socket.fromEvent('roomUsers').subscribe((data: any) => {
      if (data && data.users) {
        this.userlist = data.users.filter((e) => e.user_id !== this.user.user_id).map((e) => e.user_id)
      }
    });

    this.socket.fromEvent('userLeave').subscribe((data: any) => {
      if (data && data.user) {
        const index = this.userlist.findIndex(user => user === data.user.user_id);
        if (index !== -1) {
          this.userlist.splice(index, 1)[0]
        }
        if (this.user_avatars[data.user.user_id]) delete this.user_avatars[data.user.user_id]
        if (this.connects[data.user.user_id]) {
          this.connects[data.user.user_id].con.close()
          delete this.connects[data.user.user_id]
        }
      }
    });

    this.socket.fromEvent('disconnect').subscribe(() => {
      for (let peer in this.connects) {
        this.connects[peer].con.close();
      }
      this.connects = {}
      this.userlist = []
      this.user_avatars = {}
    });

    this.can_chat = true
  }

  async initVideo() {
    var constraints = {
      video: {
        width: { max: 320 },
        height: { max: 240 },
        frameRate: { max: 30 },
      },
      audio: true,
    };
    await navigator.mediaDevices.getUserMedia({ video: true })
      .then((stream) => {
        this.videoAvailable = true
      })
      .catch((e) => {
        this.videoAvailable = false
      })

    await navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        this.audioAvailable = true
      })
      .catch((e) => {
        this.audioAvailable = false
      })

    if (this.videoAvailable || this.audioAvailable) {
      navigator.mediaDevices.getUserMedia({
        video: this.videoAvailable && this.video ? {
          frameRate: { max: 30 },
        } : false,
        audio: this.audioAvailable ? this.audio : false
      }).then((stream) => {
        this.localMediaStream = stream;
        this.initStream(stream)
      })
        .catch((e) => console.log(e))
    } else {
      alert('Your browser does not support getUserMedia API');
    }
  }

  sendMessage() {
    if (this.selfMsg == '') return;
    this.socket.emit('message', this.selfMsg);
    this.selfMsg = '';
    this.scrollToBottom()
  }

  fullScreen() {
    this.full_screen = !this.full_screen;
    let elem = this.videoChatElem.nativeElement;
    if (!document.fullscreenElement) {
      elem.requestFullscreen().catch(err => {
        alert(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
      });
    } else {
      document.exitFullscreen();
    }

    // if (this.full_screen) {
    //   this.requestFullScreen(elem)
    // } else {
    //   this.exitFullScreen(elem)
    // }
  }
  // Bring the page into full-screen mode - Works!
  requestFullScreen(element) {

    // Supports most browsers and their versions.
    var requestMethod = element.requestFullscreen ||
      element.webkitRequestFullscreen ||
      element.mozRequestFullScreen ||
      element.msRequestFullscreen;

    if (requestMethod) {
      requestMethod.call(element);
    // } else if (typeof window.ActiveXObject !== "undefined") {
    //   var wscript = new ActiveXObject("WScript.Shell");
    //   if (wscript !== null) {
    //     wscript.SendKeys("{F11}");
    //   }
    } 
    else {
      console.log('method not found');
    }
  }

  // Exit fullscreen - Doesn't work!
  exitFullScreen(element) {
    var requestMethod = element.mozCancelFullScreen ||
      element.exitFullscreen ||
      element.webkitExitFullscreen ||
      element.msExitFullscreen;
    if (requestMethod) {
      document.exitFullscreen();
    } else {
      console.log("Oops. Request method false.");
    }
  }

  initStream(stream) {
    // this.socket.emit('streaming', { user_id: this.user.user_id, srcObject:  stream});

    this.socket.fromEvent('join').subscribe(async (data: any) => {
      // this.socket.emit('streaming', { user_id: this.user.user_id, srcObject:  JSON.stringify(stream)});
      const user = data.user;
      if (this.user_avatars[user.user_id] == undefined) {
        if (user.avatar) {
          this.user_avatars[user.user_id] = user.avatar
        } else {
          const user_profile = await this.apiService.ApiGET(`/user/getUserById/${user.user_id}`).toPromise()
          this.user_avatars[user.user_id] = user_profile.status ? user_profile.result.personal_info.profile_image : ''
        }
      }
      if (user.user_id != this.user.user_id && this.connects[user.user_id] == undefined) {
        const peerConnection = new RTCPeerConnection(
          { iceServers: ICE_SERVERS }
        );

        this.connects[user.user_id] = {
          user: user,
          con: peerConnection,
          srcObject: stream
        };

        if (!this.userlist.includes(user.user_id)) this.userlist.push(user.user_id)

        peerConnection.onicecandidate = (event) => {
          if (event.candidate) {
            this.socket.emit("relayICECandidate", {
              peer_id: user.id,
              ice_candidate: {
                sdpMLineIndex: event.candidate.sdpMLineIndex,
                candidate: event.candidate.candidate,
              },
            });
          }
        };

        peerConnection.ontrack = ({ streams: [stream] }) => {
          this.connects[user.user_id].srcObject = stream;
        };

        stream.getTracks().forEach(track => peerConnection.addTrack(track, stream));
        // this.connects[e.user_id].con
        //   .createOffer()
        //   .then(offer => this.connects[e.user_id].con.setLocalDescription(offer))
        //   .then(() => {
        //     this.socket.emit('stream', { user: e, desc: this.connects[e.user_id].con.localDescription });
        //   });
        if (data.should_create_offer)
          peerConnection
            .createOffer()
            .then(offer => this.connects[user.user_id].con.setLocalDescription(offer))
            .then(() => {
              this.socket.emit("relaySessionDescription", {
                peer_id: user.id,
                session_description: peerConnection.localDescription,
              });
            });
      }
    });

    this.socket.fromEvent('sessionDescription').subscribe((config: any) => {
      const user = config.user;
      const peer = this.connects[user.user_id].con;
      const remoteDescription = config.session_description;

      const desc = new RTCSessionDescription(remoteDescription);
      peer.setRemoteDescription(
        desc,
        () => {
          if (remoteDescription.type == "offer") {
            peer.createAnswer(
              (localDescription) => {
                peer.setLocalDescription(
                  localDescription,
                  () => {
                    this.socket.emit("relaySessionDescription", {
                      peer_id: user.id,
                      session_description: localDescription,
                    });
                  },
                  () => alert("Answer setLocalDescription failed!")
                );
              },
              (error) => console.log("Error creating answer: ", error)
            );
          }
        },
        (error) => console.log("setRemoteDescription error: ", error)
      );
    });

    this.socket.fromEvent('iceCandidate').subscribe((config: any) => {
      const user = config.user;
      const peer = this.connects[user.user_id].con;
      const iceCandidate = config.ice_candidate;
      peer.addIceCandidate(new RTCIceCandidate(iceCandidate));
    });

    this.socket.fromEvent('stream').subscribe((res: any) => {
      if (res.desc.type == 'answer') {
        if (!this.userlist.includes(res.id)) this.userlist.push(res.id)
      }

      if (res.desc.type == 'offer' && this.connects[res.user.user_id] == undefined) {
        if (!(this.connects[res.user.user_id])) {
          this.connects[res.user.user_id] = {
            user: res.user,
            con: new RTCPeerConnection()
          }
          if (!this.userlist.includes(res.user.user_id) && res.user.user_id !== this.user.user_id) this.userlist.push(res.user.user_id)
        }
        this.connects[res.user.user_id].con.onicecandidate = ({ candidate }) => {
          candidate && this.socket.emit('candidate', res.user.id, candidate);
        };

        // Receive stream from remote client and add to remote video area
        this.connects[res.user.user_id].con.ontrack = ({ streams: [stream] }) => {
          this.connects[res.user.user_id].srcObject = stream;

        };
        // Set Local And Remote description and create answer
        this.connects[res.user.user_id].con
          .setRemoteDescription(new RTCSessionDescription(res.desc))
          .then(() => this.connects[res.user.user_id].con.createAnswer())
          .then(answer => this.connects[res.user.user_id].con.setLocalDescription(answer))
          .then(() => {
            this.socket.emit('stream', { id: res.user.user_id, desc: this.connects[res.user.user_id].con.localDescription });
          });

      }
    });
  }

  endCall() {
    this.socket.emit('endcall', this.user)
    this.router.navigate(["/appointment/manage-appointment"]);

  }
}
